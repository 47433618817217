import { Button, TextField, type DialogProps } from '@mui/material';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useCreateChannelGuide } from '~/api/channel-guides';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent, DialogForm } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';

export interface NewChannelGuideDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
  open: boolean;
}

export const NewChannelGuideDialog = ({ close, open }: NewChannelGuideDialogProps) => {
  const { currentNetwork } = useAppContext();

  const [createChannelGuide] = useCreateChannelGuide();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
    },
    onSubmit: async (input) => {
      const { errors } = await createChannelGuide({
        variables: { input: { ...input, networkId: currentNetwork.id } },
      });
      if (!errors?.length) close();
    },
    validateOnBlur: true,
    validateOnMount: true,
    validationSchema: object({
      name: string().required().label('Name'),
    }),
  });

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
    >
      <DialogTitle onClose={close}>New Channel Guide</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Channel Guide.</DialogContentTitle>

        <DialogForm onSubmit={formik.handleSubmit} id="new-channel-guide-form">
          <label>
            <span className={`label required`}>Name</span>
            <TextField
              error={formik.touched.name && !!formik.errors.name}
              fullWidth
              helperText={(formik.touched.name && formik.errors.name) || ' '}
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              focused
              autoFocus
            />
          </label>
        </DialogForm>
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          form="new-channel-guide-form"
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
