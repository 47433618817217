import { useMutation } from '@apollo/client';
import { pluralize } from '~/lib/string';
import { useNotifications, type ApiOptions } from '../helpers';
import { AddChannelsToChannelGuideDocument } from './add-channels.generated';

export const useAddChannelsToChannelGuide = (
  options: ApiOptions<typeof AddChannelsToChannelGuideDocument> = {},
) =>
  useMutation(AddChannelsToChannelGuideDocument, {
    ...useNotifications(AddChannelsToChannelGuideDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.addChannelsToChannelGuide?.addedIds.length;
        return `Added ${count} ${pluralize('Channel', count ?? 0)} to ${data
          .addChannelsToChannelGuide?.channelGuide.name}`;
      },
    }),

    ...options,
  });
