import { useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, MenuItem, Select, Typography, type DialogProps } from '@mui/material';
import { useState } from 'react';
import { useAddChannelsToChannelGuide } from '~/api/channel-guides';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import { assert } from '~/lib/assert';
import { AvailableChannelGuidesDocument } from '../queries/available-channel-guides.generated';

export interface AddChannelToGuideDialogProps extends Omit<DialogProps, 'children'> {
  channelId: number;
  close: () => void;
}

export const AddChannelToGuideDialog = ({
  channelId,
  close,
  ...props
}: AddChannelToGuideDialogProps) => {
  const { currentNetwork } = useAppContext();

  const { data } = useQuery(AvailableChannelGuidesDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const [addChannelsToChannelGuide, { called, reset, loading }] = useAddChannelsToChannelGuide();

  const [channelGuideId, setChannelGuideId] = useState<number | ''>('');

  const channelGuides = data?.network?.channelGuides ?? [];

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={close}
      TransitionProps={{ onExited: () => setChannelGuideId('') }}
      {...props}
    >
      <DialogTitle onClose={close}>Add to Channel Guide</DialogTitle>

      <DialogContent>
        <DialogContentTitle>
          Select the Channel Guide where you would like to add your Channel.
        </DialogContentTitle>
        <label>
          <Typography gutterBottom>Channel Guide</Typography>
          <Select
            displayEmpty
            fullWidth
            onChange={(event) =>
              typeof event.target.value === 'number' && setChannelGuideId(event.target.value)
            }
            value={channelGuideId}
          >
            <MenuItem disabled value="">
              Select one...
            </MenuItem>
            {channelGuides.map((channelGuide) => (
              <MenuItem key={channelGuide.id} value={channelGuide.id}>
                {channelGuide.name}
              </MenuItem>
            ))}
          </Select>
        </label>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          color="primary"
          disabled={channelGuideId === ''}
          onClick={async () => {
            assert(channelGuideId !== '', 'no channel guide selected');
            assert(data != null, 'no channel id selected');
            await addChannelsToChannelGuide({
              variables: { input: { channelGuideId, channelIds: [channelId] } },
            });
            close();
            reset();
          }}
          variant="contained"
          startIcon={<Add />}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
