import { useQuery } from '@apollo/client';
import { Edit } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { DataItemSelectListDocument } from './DataItemSelect.generated';
import { DataItemSelectModal } from './components';
import { getLabel, FlexEnd, useCachedDataItem } from './lib';

export interface DataItemSelectProps {
  dataItemId: number | undefined;
  disabled?: boolean;
  onChange: (dataItemId: number | null) => void;
  optionName: string;
}

export const DataItemSelect = ({
  dataItemId,
  disabled,
  onChange,
  optionName,
}: DataItemSelectProps) => {
  const dataItem = useCachedDataItem(dataItemId);
  const link = useLink();

  const { currentNetwork } = useAppContext();
  const [open, setOpen] = useState(false);

  const { data: sourcesQuery } = useQuery(DataItemSelectListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const dataSources = useMemo(() => sourcesQuery?.network?.dataSources.nodes || [], [sourcesQuery]);

  return (
    <>
      {dataItem && (
        <FlexEnd>
          <Link
            color="text.primary"
            target="_blank"
            to={link(`/data-sources/${dataItem.source.id}/edit?#${dataItem.id}`)}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {getLabel(dataItem)}
          </Link>
          <Tooltip title="Change selected Data Source item">
            <Edit
              fontSize="small"
              onClick={() => setOpen(() => true)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            />
          </Tooltip>
        </FlexEnd>
      )}

      {!dataItem && (
        <Tooltip title="Select an item from a Data Source">
          <Button
            disabled={disabled}
            onClick={() => setOpen(() => true)}
            sx={{ alignSelf: 'flex-end' }}
            variant="contained"
          >
            Select
          </Button>
        </Tooltip>
      )}

      {open && (
        <DataItemSelectModal
          dataItem={dataItem}
          dataSources={dataSources}
          onChange={onChange}
          onClose={() => setOpen(() => false)}
          optionName={optionName}
        />
      )}
    </>
  );
};
