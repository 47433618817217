import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateChannelDocument } from './update.generated';

export const useUpdateChannel = (options: ApiOptions<typeof UpdateChannelDocument> = {}) =>
  useMutation(UpdateChannelDocument, {
    ...useNotifications(UpdateChannelDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated Channel',
    }),

    ...options,
  });
