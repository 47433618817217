import { Button, type DialogProps } from '@mui/material';
import { useCreateChannel } from '~/api/channels';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import { ChannelEncryptionKind } from '~/generated/graphql';
import { ChannelForm } from '../detail/components/form';
import { useChannelFormik } from '../detail/lib/formik';

export interface NewChannelDialogProps extends Omit<DialogProps, 'children'> {
  close: () => void;
}

export const NewChannelDialog = ({ close, ...props }: NewChannelDialogProps) => {
  const { currentNetwork } = useAppContext();
  const [createChannel] = useCreateChannel();

  const formik = useChannelFormik(
    {
      backgroundColor: 'black',
      encryption: ChannelEncryptionKind.None,
      name: '',
      number: '',
      source: '',
      thumbnailUri: '',
      url: '',
      urls: [],
    },
    async (input) => {
      await createChannel({ variables: { input, networkId: currentNetwork.id } });
      close();
    },
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={close}
      TransitionProps={{ onExited: () => formik.resetForm() }}
      {...props}
    >
      <DialogTitle onClose={close}>New Channel</DialogTitle>

      <DialogContent>
        <DialogContentTitle>Create a new Channel to use in Channel Guides.</DialogContentTitle>

        <ChannelForm formik={formik} id="new-channel-form" />
      </DialogContent>

      <DialogActions>
        <Button disabled={formik.isSubmitting} onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!formik.isValid || formik.isSubmitting}
          form="new-channel-form"
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
