import { ExpandMore, type SvgIconComponent } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useCallback } from 'react';
import type { DeviceFilterItems } from '~/pages/devices/list/context';
import { FilterCheckbox } from '~/pages/devices/list/lib/styles';

export interface FilterCollapsibleListProps<T> {
  Icon?: SvgIconComponent;
  expanded?: boolean;
  items: DeviceFilterItems<T>[];
  label?: string;
  loading?: boolean;
  onClick: (value: T, newState: DeviceFilterItems<T>[]) => void;
  onExpand?: () => void;
}

export function FilterCollapsibleList<T>({
  Icon,
  expanded = false,
  loading = false,
  items,
  label,
  onClick,
  onExpand,
}: FilterCollapsibleListProps<T>) {
  const handleToggle = useCallback(
    (value: T) => {
      const newState = items.map((x) => ({
        ...x,
        selected: x.value === value ? !x.selected : x.selected,
      }));
      onClick(value, newState);
    },
    [items, onClick],
  );

  return (
    <Accordion
      disableGutters
      onChange={onExpand}
      expanded={expanded}
      sx={{
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          minHeight: '40px',
          '&.Mui-expanded': { minHeight: '40px' },
          '.MuiAccordionSummary-content': {
            fontSize: '.7rem',
            fontWeight: 600,
            margin: 0,
            textTransform: 'uppercase',
          },
        }}
      >
        {Icon && <Icon color="primary" sx={{ mr: 1 }} />}
        {label}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          '& > *': { width: '100%' },
          alignItems: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          padding: (theme) => theme.spacing(1, 0, 0, 0),
        }}
      >
        <List dense sx={{ width: '100%' }}>
          {items.map(({ label, selected, value, count }) => (
            <ListItem key={label} disableGutters disablePadding>
              <ListItemButton
                sx={{ py: 0, borderRadius: '4px' }}
                onClick={() => handleToggle(value)}
              >
                <ListItemIcon>
                  <FilterCheckbox edge="start" checked={selected} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{ sx: { fontSize: '.875rem' } }}
                />
                {count !== undefined && (
                  <Chip
                    label={loading ? <CircularProgress size={12} color="inherit" /> : count}
                    color={selected ? 'primary' : 'default'}
                  />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
