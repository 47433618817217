import { AddCircle, Edit, RemoveCircle } from '@mui/icons-material';
import { Box, Button, Paper, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useUpdateChannelGuide } from '~/api/channel-guides';
import { ReadOnlyDataGrid } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { EmptyState } from '~/components/empty-state';
import { SearchBar } from '~/components/forms/search-bar';
import { SearchContainer } from '~/components/search';
import { TableAction } from '~/components/table';
import { useConfirmDialog } from '~/hooks/dialogs';
import CreateChannelGuideIllustration from '~/images/illustrations/create-channel-guide.svg';
import { searchRegex } from '~/lib/search';
import { EditChannelDialog } from '~/pages/channels/components/EditDialog';
import { formatEncryption } from '~/pages/channels/lib/formatters';
import { AddChannelsDialog } from '../../components/AddChannelsDialog';
import type {
  ChannelGuideSettings__Channel as Channel,
  ChannelGuideSettings__ChannelGuide as ChannelGuide,
} from '../../queries/settings.generated';

export interface ChannelsProps {
  channelGuide: ChannelGuide;
  channels: readonly Channel[];
}

export const Channels = ({ channelGuide, channels }: ChannelsProps) => {
  const theme = useTheme();

  const [currentChannel, setCurrentChannel] = useState<Channel>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addChannelsDialogOpen, setAddChannelsDialogOpen] = useState(false);

  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});

  const [search, setSearch] = useState('');

  const channelGuideChannels = useMemo(() => {
    const searchReg = searchRegex(search);
    return channelGuide.channels.filter((item) => searchReg.test(item.name));
  }, [channelGuide.channels, search]);

  const [confirmRemoveChannel, confirmRemoveChannelProps] = useConfirmDialog();

  const [updateChannelGuide] = useUpdateChannelGuide();

  const removeChannel = useCallback(
    async (channel: Channel) => {
      if (!(await confirmRemoveChannel())) return;

      await updateChannelGuide({
        variables: {
          input: {
            id: channelGuide.id,
            patch: {
              channelIds: channelGuide.channels.filter((x) => x.id !== channel.id).map((x) => x.id),
            },
          },
        },
      });
    },
    [channelGuide.channels, channelGuide.id, confirmRemoveChannel, updateChannelGuide],
  );

  const editChannel = (channel: Channel) => {
    setCurrentChannel(channel);
    setEditDialogOpen(true);
  };

  const columns: GridColDef<Channel>[] = useMemo(
    () => [
      {
        field: 'thumbnailFile',
        headerName: 'Thumbnail',
        minWidth: 100,
        renderCell: ({ row: channel, value: thumbnailFile }) => {
          return thumbnailFile ? (
            <Tooltip title={`Channel ID: ${channel.id}`} arrow>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                  backgroundColor: channel.backgroundColor,
                  height: '75%',
                }}
              >
                <img
                  crossOrigin="anonymous"
                  src={thumbnailFile.uri}
                  style={{ margin: '0.5rem', height: '100%' }}
                />
              </Box>
            </Tooltip>
          ) : null;
        },
        sortable: false,
      } satisfies GridColDef<Channel, Channel['thumbnailFile']>,
      {
        field: 'number',
        resizable: false,
        sortable: true,
        minWidth: 100,
      },
      {
        field: 'name',
        flex: 1,
        minWidth: isSmallAndUp ? 180 : 0,
        resizable: true,
        sortable: true,
      },
      {
        field: 'url',
        minWidth: 300,
        resizable: true,
        sortable: true,
      },
      {
        field: 'encryption',
        resizable: false,
        sortable: false,
        width: 110,
        valueFormatter: ({ value }) => formatEncryption(value),
      } satisfies GridColDef<Channel, Channel['encryption'], string>,
      {
        field: 'source',
        minWidth: 125,
        resizable: false,
        sortable: true,
      },
      {
        align: 'right',
        field: 'actions',
        getActions: ({ id, row }) => {
          return [
            <TableAction
              key={id}
              title="Edit Channel"
              Icon={Edit}
              onClick={() => {
                editChannel(row);
              }}
            />,
            <TableAction
              color="error"
              key={id}
              title="Remove Channel"
              Icon={RemoveCircle}
              onClick={() => removeChannel(row)}
            />,
          ];
        },
        headerName: '',
        sortable: false,
        type: 'actions',
      },
    ],
    [isSmallAndUp, removeChannel, theme],
  );

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      encryption: !isSmallAndDown,
      source: !isSmallAndDown,
      thumbnailFile: !isSmallAndDown,
      url: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
              gap: theme.spacing(1),
            },
          }}
        >
          <Box sx={{ alignItems: 'center', display: 'flex', flex: 1, gap: 2 }}>
            <Box>
              <Typography variant="h4">Channels</Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                Add Channels to this Channel Guide to display on the Electronic Programming Guide
                (EPG).
              </Typography>
            </Box>
          </Box>
          <Button
            title="foobar"
            color="primary"
            onClick={() => setAddChannelsDialogOpen(() => true)}
            startIcon={<AddCircle />}
            variant="contained"
          >
            Add Channels
          </Button>
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(2),
            borderTop: '1px solid',
            borderColor: '#dedcdc',
          }}
        >
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SearchContainer>
                <SearchBar onChange={setSearch} placeholder="Search Channels" search={search} />
              </SearchContainer>
            </Box>
            <ReadOnlyDataGrid
              columns={columns}
              columnVisibilityModel={showColumns}
              disableRowSelectionOnClick
              onRowClick={({ row }: GridRowParams<Channel>) => {
                console.log('channel clicked', row);
              }}
              hideFooter
              rows={channelGuideChannels}
              slots={{
                noRowsOverlay: () => {
                  return (
                    <EmptyState
                      illustration={CreateChannelGuideIllustration}
                      header="Create a Channel"
                      description="Channels represent IPTV content."
                    />
                  );
                },
              }}
            />
          </>
        </Box>
      </Paper>
      <AddChannelsDialog
        channelGuide={channelGuide}
        channels={channels}
        close={() => setAddChannelsDialogOpen(false)}
        open={addChannelsDialogOpen}
      />
      {currentChannel && (
        <EditChannelDialog
          close={() => setEditDialogOpen(false)}
          open={editDialogOpen}
          channel={currentChannel}
        />
      )}
      <ConfirmDialog
        {...confirmRemoveChannelProps}
        confirm="Remove"
        deleteConfirm
        prompt="Are you sure you want to remove the Channel from this Channel Guide?"
        title="Remove Channel"
      />
    </>
  );
};
