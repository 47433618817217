export const tabs = [
  { label: 'Details', to: '../details' },
  { label: 'Users', to: '../users' },
  { label: 'Devices', to: '../devices' },
  { label: 'Shows', to: '../shows' },
  { label: 'Playlists', to: '../playlists' },
  { label: 'Content Folders', to: '../content-folders' },
  { label: 'Data Sources', to: '../data-sources' },
  { label: 'Audit Logs', to: '../audit-logs' },
];
