import { useQuery } from '@apollo/client';
import { Cloud } from '@mui/icons-material';
import { Box } from '@mui/material';
import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { DataGridPagination } from '~/components/data-grid';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { ListRowHeadingValue } from '~/components/list-row';
import { LoadingPane } from '~/components/loading-pane';
import { SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { useAppContext } from '~/contexts';
import { DataSourceKind } from '~/generated/graphql';
import { useLink } from '~/hooks/link';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import csv from '~/images/csv.svg';
import xlsx from '~/images/xlsx.svg';
import { formatDate } from '~/lib/datetime';
import { EditDataSourceToolbar, SpreadsheetToolbar } from './components';
import { DataSourceProvider, Layout } from './context';
import { MetaItem, SpreadsheetyDataGrid, useSpreadsheetUtils } from './lib';
import { DataSourceGetDocument } from './queries/get.generated';

export const EditDataSource = () => {
  const params = useParams<{ dataSourceId: string }>();
  const dataSourceId = parseInt(params.dataSourceId ?? '');

  const { hash } = useLocation();
  const highlightedId = useMemo(() => (hash ? Number(hash.split('#').at(1)) : undefined), [hash]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { page, paginationModel, perPage, setPageParams } = usePaginationParams();

  const onPaginationModelChange = useCallback(
    ({ page, pageSize }: GridPaginationModel) => {
      setPageParams({ page: page + 1, perPage: pageSize });
    },
    [setPageParams],
  );

  const search = useMemo(() => searchParams.get('search') ?? '', [searchParams]);
  const onSearch = useCallback((search: string) => setSearchParams({ search }), [setSearchParams]);

  const { data, loading } = useQuery(DataSourceGetDocument, {
    variables: {
      dataSourceId,
      networkId: currentNetwork.id,
      orderFirst: highlightedId,
      page,
      perPage,
      search,
    },
  });

  const totalCount = useTotalCount(data?.network?.dataSource.items.pageInfo.nodesCount);

  const dataSource = useMemo(() => data?.network?.dataSource, [data]);
  const {
    cellMode,
    cellModesModel,
    columns,
    confirmDeleteProps,
    handleAddRow,
    handleCellEditStop,
    handleCellFocus,
    highlightedIndex,
    loadingEdit,
    processRowUpdate,
    rows,
    selectedCellParams,
    setCellModesModel,
    setSelectedCellParams,
  } = useSpreadsheetUtils(dataSource, highlightedId);

  if (!loading && !data?.network?.dataSource) return <Navigate to={link('/not-found')} replace />;

  if (!dataSource) return null;

  return (
    <DataSourceProvider dataSource={dataSource}>
      <LoadingPane in={loading && !data} size={80} thickness={4}>
        <EditDataSourceToolbar />
        <Layout>
          <Box>
            <SettingsHead>Details</SettingsHead>
            <SettingsBody>
              <SettingsData className="last no-space-between">
                <Box sx={{ display: 'flex' }}>
                  <MetaItem className="first">
                    <ListRowHeadingValue
                      heading="Source"
                      value={
                        <Box sx={{ display: 'flex', gap: 0.5 }}>
                          {dataSource.kind === DataSourceKind.Integration ? (
                            <Cloud color="primary" />
                          ) : (
                            <img src={dataSource.kind === DataSourceKind.Csv ? csv : xlsx} />
                          )}
                          {dataSource.kind}
                        </Box>
                      }
                    />
                  </MetaItem>
                  <MetaItem>
                    <ListRowHeadingValue
                      heading="Last Updated"
                      value={formatDate(dataSource.updatedAt)}
                    />
                  </MetaItem>
                  <MetaItem>
                    <ListRowHeadingValue heading="Columns" value={dataSource.keys.length} />
                  </MetaItem>
                  <MetaItem>
                    <ListRowHeadingValue heading="Rows" value={totalCount} />
                  </MetaItem>
                  <MetaItem className="last">
                    <ListRowHeadingValue
                      heading="Unique ID"
                      value={dataSource.foreignIdName || '-'}
                    />
                  </MetaItem>
                </Box>
              </SettingsData>
            </SettingsBody>
          </Box>
          <Box sx={{ mt: 2 }}>
            <SettingsHead>Data</SettingsHead>
            <SettingsBody>
              <SettingsData className="last">
                <Box sx={{ width: '100%' }}>
                  <SpreadsheetyDataGrid
                    cellModesModel={cellModesModel}
                    columns={columns}
                    disableColumnMenu
                    disableRowSelectionOnClick
                    getRowClassName={({ row }) => (row.__enabled__ === 'false' ? 'disabled' : '')}
                    hideFooterSelectedRowCount
                    initialState={{
                      pagination: {
                        paginationModel: { pageSize: 50, page: 0 },
                      },
                    }}
                    localeText={{
                      MuiTablePagination: {
                        labelRowsPerPage: 'Per page',
                      },
                    }}
                    onCellEditStop={handleCellEditStop}
                    onCellModesModelChange={setCellModesModel}
                    onPaginationModelChange={onPaginationModelChange}
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    pinnedColumns={{ right: ['actions'] }}
                    processRowUpdate={processRowUpdate}
                    rowCount={totalCount}
                    rowSelectionModel={highlightedIndex ? [highlightedIndex] : []}
                    rows={rows}
                    slotProps={{
                      toolbar: {
                        cellMode,
                        cellModesModel,
                        editable: dataSource.kind !== 'INTEGRATION',
                        loadingEdit,
                        onAddRow: handleAddRow,
                        onSearch,
                        search,
                        selectedCellParams,
                        setCellModesModel,
                        setSelectedCellParams,
                      },
                      cell: { onFocus: handleCellFocus },
                    }}
                    slots={{
                      pagination: DataGridPagination,
                      toolbar: SpreadsheetToolbar,
                    }}
                  />
                </Box>
              </SettingsData>
            </SettingsBody>
          </Box>
        </Layout>
      </LoadingPane>
      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Delete"
        deleteConfirm
        prompt="Are you sure you want to delete this row?"
        title="Delete Row"
      />
    </DataSourceProvider>
  );
};
