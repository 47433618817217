import { CloudUpload } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useUploadDataSource } from '~/api/data-sources';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useAppContext } from '~/contexts';
import { DataSourceKind } from '~/generated/graphql';
import { useConfirmDialog } from '~/hooks/dialogs';
import { toDataUri } from '~/lib/file';
import { useDataSource } from '../context';

export const UploadDataSourceButton = () => {
  const { currentNetwork } = useAppContext();
  const dataSource = useDataSource();
  const [uploadDataSource] = useUploadDataSource();
  const [confirm, confirmProps] = useConfirmDialog();

  const { getInputProps, open: selectFile } = useDropzone({
    accept:
      dataSource.kind === DataSourceKind.Csv
        ? 'text/csv'
        : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const dataUri = await toDataUri(accepted[0]);
      await uploadDataSource({
        variables: {
          dataUri,
          dataSourceId: dataSource.id,
          networkId: currentNetwork.id,
          name: dataSource.name,
        },
      });
    },
  });

  return (
    <>
      <input {...getInputProps()} />
      <Button
        disabled={!dataSource.canUpdateItems.value}
        color="primary"
        startIcon={<CloudUpload />}
        variant="contained"
        onClick={async () => {
          if (!(await confirm())) return;
          selectFile();
        }}
      >
        Upload
      </Button>
      <ConfirmDialog
        {...confirmProps}
        confirmButton={
          <Button onClick={confirmProps.onConfirm} startIcon={<CloudUpload />} variant="contained">
            Upload
          </Button>
        }
        prompt={
          dataSource.foreignIdName
            ? `Your unique id "${dataSource.foreignIdName}" will be used to update existing data or create new rows based on the file you upload.`
            : 'Any apps that currently use this data source will have to be updated to reference the newly created data.'
        }
        title={dataSource.foreignIdName ? 'Update This Data Source' : 'Re-Upload This Data Source?'}
      />
    </>
  );
};
