import { useQuery } from '@apollo/client';
import { Router, Sell, ViewHeadline, ViewModule } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import { SelectedCount } from '~/components/data-grid/SelectedCount';
import { SendCommandDialog, useSendCommandDialog } from '~/components/devices/send-command-dialog';
import { FilterSidebar, FilterToggle } from '~/components/Filter';
import { SearchBar } from '~/components/forms/search-bar';
import {
  ListActions,
  ListContainer,
  ListContent,
  ListHeader,
  ListPageContainer,
} from '~/components/PageLayout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import type { DevicesOrderBy } from '~/generated/graphql';
import { useRowSelectionMode } from '~/hooks/data-grid';
import { useOrderByParams } from '~/hooks/order-by';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import {
  PlayOverlayDialog,
  TuneChannelDialog,
  UpdateSoftwareDialog,
  VolumeDialog,
} from '../components';
import { DeviceListDocument } from '../queries/list.generated';
import {
  BulkActions,
  BulkDeviceGroupDialog,
  FilterAccordion,
  FilterChips,
  Grid,
  Table,
  TagsDialog,
} from './components';
import { BulkScheduleDialog } from './components/bulk-schedule-dialog';
import { BulkTagDialog } from './components/bulk-tag-dialog';
import { BulkDeviceSettingsDialog } from './components/BulkSettingsDialog';
import { StatusToggle } from './components/StatusToggle';
import { DeviceFiltersProvider, useDeviceFiltersParams, useDeviceFiltersReducer } from './context';
import { ActivateButton, ButtonToggle, ViewToggle } from './lib';
import { generateScheduledShowName } from './lib/helpers';

export const DeviceList = () => {
  const [openCommandDialog, commandDialogProps] = useSendCommandDialog();
  const [tagContentDialogOpen, setTagContentDialogOpen] = useState(false);
  const [tuneChannelDialogOpen, setTuneChannelDialogOpen] = useState(false);
  const [playOverlayDialogOpen, setPlayOverlayDialogOpen] = useState(false);
  const [updateSettingsDialogOpen, setUpdateSettingsDialogOpen] = useState(false);
  const [updateSoftwareDialogOpen, setUpdateSoftwareDialogOpen] = useState(false);
  const [volumeDialogOpen, setVolumeDialogOpen] = useState(false);
  const [manageTagsOpen, setManageTagsOpen] = useState(false);
  const [bulkScheduleOpen, setBulkScheduleOpen] = useState(false);
  const [addToGroupOpen, setShowAddToGroupOpen] = useState(false);

  const { handleRowSelectionModeChange, rowSelectionMode, selectedIds, setSelectedIds } =
    useRowSelectionMode();

  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';
  const deviceView = searchParams.get('view')?.trim() || 'List';

  const filterParams = useDeviceFiltersParams();

  const condition = useMemo(
    () => ({ ...filterParams, networkId: currentNetwork.id, search }),
    [currentNetwork.id, filterParams, search],
  );

  const filtersActive = useMemo(
    () => Object.values(filterParams).flat().length > 0,
    [filterParams],
  );

  const { orderBy } = useOrderByParams<DevicesOrderBy>();
  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(DeviceListDocument, {
    variables: {
      condition,
      networkId: currentNetwork.id,
      ...(orderBy && { orderBy }),
      page,
      perPage,
    },
  });

  const totalCount = useTotalCount(data?.network?.devices.pageInfo.nodesCount);

  const selectedDevices = useMemo(
    () => (rowSelectionMode === 'page' ? { ids: selectedIds.map(Number) } : { condition }),
    [condition, rowSelectionMode, selectedIds],
  );

  // reset selections when applying filters
  useEffect(() => {
    setSelectedIds([]);
  }, [setSelectedIds]);

  const handleAddToGroup = useCallback(() => {
    setShowAddToGroupOpen(() => true);
  }, []);

  const handleSendCommand = useCallback(() => {
    openCommandDialog(selectedDevices);
  }, [openCommandDialog, selectedDevices]);

  const handleScheduleShow = useCallback(() => {
    setBulkScheduleOpen(true);
  }, []);

  const [deviceFiltersState, deviceFiltersDispatch] = useDeviceFiltersReducer(
    filterParams,
    data?.network?.deviceTags.map((x) => x.name),
    data?.network?.deviceGroups,
  );

  const deviceFilterInfo = data?.network?.deviceFilterInfo;

  return (
    <DeviceFiltersProvider
      {...deviceFiltersState}
      clearFilters={() => deviceFiltersDispatch({ type: 'CLEAR_FILTERS' })}
      setFilter={(value) => deviceFiltersDispatch({ type: 'SET_FILTER', ...value })}
      toggleFilter={(field) => deviceFiltersDispatch({ type: 'TOGGLE_FILTER', field })}
      togglePanel={() => deviceFiltersDispatch({ type: 'TOGGLE_PANEL' })}
    >
      <Helmet title="Devices" />
      {/* TODO: don't show for content editor, needs fix */}
      <Toolbar
        titleIcon={<Router />}
        titleText="Devices"
        actions={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => setManageTagsOpen(!manageTagsOpen)}
              startIcon={<Sell />}
              variant="outlined"
            >
              Manage Tags
            </Button>
            {currentNetwork.canActivateDevices.value && <ActivateButton />}
          </Box>
        }
      />

      <ListPageContainer>
        <FilterSidebar open={deviceFiltersState.panel.open}>
          <FilterAccordion filterCounts={deviceFilterInfo} />
        </FilterSidebar>
        <ListContainer filterOpen={deviceFiltersState.panel.open}>
          <ListHeader>
            <ListActions>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <FilterToggle
                  active={filtersActive}
                  onToggle={() => deviceFiltersDispatch({ type: 'TOGGLE_PANEL' })}
                />
                <SearchBar
                  onChange={(value) =>
                    setSearchParams((params) => {
                      params.set('search', value);
                      params.set('page', '1');
                      return params;
                    })
                  }
                  placeholder="Search Devices"
                  search={search}
                />
                <StatusToggle filterCounts={deviceFilterInfo} />
              </Box>
              <Box sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center' }}>
                {selectedIds.length > 0 ? (
                  <BulkActions
                    onAddToGroup={handleAddToGroup}
                    onPlayOverlay={() => setPlayOverlayDialogOpen(true)}
                    onSendCommand={handleSendCommand}
                    onScheduleShow={handleScheduleShow}
                    onTagContent={() => setTagContentDialogOpen(true)}
                    onTuneChannel={() => setTuneChannelDialogOpen(true)}
                    onUpdateSettings={() => setUpdateSettingsDialogOpen(true)}
                    onUpdateSoftware={() => setUpdateSoftwareDialogOpen(true)}
                    onVolume={() => setVolumeDialogOpen(true)}
                  />
                ) : null}
                <SelectedCount
                  rowSelectionMode={rowSelectionMode}
                  selectedCount={selectedIds.length}
                  thing="device"
                  totalCount={totalCount}
                />
              </Box>
            </ListActions>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  flexWrap: 'wrap',
                  visibility: filtersActive ? 'visible' : 'hidden',
                }}
              >
                <FilterChips />
              </Box>
              <ViewToggle>
                <ButtonToggle
                  value="List"
                  deviceView={deviceView === 'List'}
                  onClick={() =>
                    setSearchParams((prev) => {
                      return { ...Object.fromEntries(prev), view: 'List' };
                    })
                  }
                >
                  <ViewHeadline />
                </ButtonToggle>
                <ButtonToggle
                  value="Grid"
                  deviceView={deviceView === 'Grid'}
                  onClick={() =>
                    setSearchParams((prev) => ({ ...Object.fromEntries(prev), view: 'Grid' }))
                  }
                >
                  <ViewModule />
                </ButtonToggle>
              </ViewToggle>
            </Box>
          </ListHeader>
          <ListContent>
            {deviceView === 'List' ? (
              <Table
                devices={data?.network?.devices.nodes ?? []}
                handleRowSelectionModeChange={handleRowSelectionModeChange}
                loading={loading}
                onCheck={setSelectedIds}
                onSendCommand={openCommandDialog}
                rowSelectionMode={rowSelectionMode}
                selectedIds={selectedIds}
                totalCount={totalCount}
              />
            ) : (
              <Grid
                devices={data?.network?.devices.nodes ?? []}
                onCheck={setSelectedIds}
                onSendCommand={openCommandDialog}
                selectedIds={selectedIds}
                totalCount={totalCount}
              />
            )}
          </ListContent>
          {addToGroupOpen && (
            <BulkDeviceGroupDialog
              deviceGroups={data?.network?.deviceGroups || []}
              onClose={() => setShowAddToGroupOpen(() => false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              selected={selectedDevices}
            />
          )}
          {bulkScheduleOpen && (
            <BulkScheduleDialog
              name={generateScheduledShowName(
                data?.network?.deviceGroups.map((item) => ({
                  id: item.id,
                  name: item.name,
                })) ?? [],
                { ...filterParams, search },
              )}
              onClose={() => {
                setBulkScheduleOpen(false);
              }}
              onSubmit={() => {
                setBulkScheduleOpen(false);
                setSelectedIds([]);
              }}
              open
              selected={selectedDevices}
            />
          )}
          <SendCommandDialog {...commandDialogProps} onComplete={() => setSelectedIds([])} />
          {manageTagsOpen && <TagsDialog onClose={() => setManageTagsOpen(false)} open />}

          {tagContentDialogOpen && (
            <BulkTagDialog
              deviceTags={data?.network?.deviceTags.map((x) => x.name) || []}
              onClose={() => setTagContentDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              selected={selectedDevices}
            />
          )}

          {tuneChannelDialogOpen && (
            <TuneChannelDialog
              onClose={() => setTuneChannelDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              selected={selectedDevices}
            />
          )}

          {playOverlayDialogOpen && (
            <PlayOverlayDialog
              onClose={() => setPlayOverlayDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              selected={selectedDevices}
            />
          )}

          {updateSettingsDialogOpen && (
            <BulkDeviceSettingsDialog
              channels={data?.network?.channels || []}
              deviceGroups={data?.network?.deviceGroups || []}
              onClose={() => setUpdateSettingsDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              selected={selectedDevices}
              selectedDeviceCount={selectedDevices.ids ? selectedDevices.ids.length : totalCount}
              tvBrands={data?.network?.tvBrands || []}
            />
          )}

          {updateSoftwareDialogOpen && (
            <UpdateSoftwareDialog
              onClose={() => setUpdateSoftwareDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              selected={selectedDevices}
            />
          )}

          {volumeDialogOpen && (
            <VolumeDialog
              onClose={() => setVolumeDialogOpen(false)}
              onComplete={() => setSelectedIds(() => [])}
              open
              selected={selectedDevices}
            />
          )}
        </ListContainer>
      </ListPageContainer>
    </DeviceFiltersProvider>
  );
};
