import { useFormik } from 'formik';
import { useMemo } from 'react';
import { number, object } from 'yup';
import { useUpdateChannelGuide } from '~/api/channel-guides';
import { ChannelAutocomplete } from '~/components/channels';
import type { ChannelGuideSettingsProps } from '../components/settings-form';

const validationSchema = object({
  defaultChannelId: number().nullable().optional(),
});

export const useSettingsForm = ({ channelGuide, channels }: ChannelGuideSettingsProps) => {
  const [updateChannelGuide] = useUpdateChannelGuide();

  const initialValues = useMemo(
    () => ({
      defaultChannelId: channelGuide.defaultChannel?.id ?? '',
    }),
    [channelGuide],
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      await updateChannelGuide({
        variables: {
          input: { id: channelGuide.id, patch: validationSchema.cast(values) },
        },
      });
    },
    validateOnBlur: true,
    validationSchema,
  });

  const defaultChannel = useMemo(
    () => channels.find(({ id }) => id === formik.values.defaultChannelId) ?? null,
    [channels, formik.values.defaultChannelId],
  );

  const availableChannels = useMemo(() => channelGuide.channels, [channelGuide]);

  const generalSettings = useMemo(() => {
    return [
      {
        heading: 'Default Channel',
        subHeading: 'Initial channel that is automatically tuned',
        dataField: (
          <ChannelAutocomplete
            disabled={availableChannels.length === 0}
            isOptionEqualToValue={(option, value) => value.id === option.id}
            onChange={(_event, channel) =>
              formik.setFieldValue('defaultChannelId', channel?.id ?? null)
            }
            options={availableChannels}
            value={defaultChannel}
          />
        ),
      },
    ];
  }, [availableChannels, defaultChannel, formik]);

  return {
    formik,
    generalSettings,
  };
};
