import { useMutation } from '@apollo/client';
import { DeleteChannelGuideDocument } from '../channel-guides/delete.generated';
import { useNotifications, type ApiOptions } from '../helpers';

export const useDeleteChannelGuide = (
  options: ApiOptions<typeof DeleteChannelGuideDocument> = {},
) =>
  useMutation(DeleteChannelGuideDocument, {
    ...useNotifications(DeleteChannelGuideDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteChannelGuide?.deletedIds.length;
        return `Deleted ${count != null && count > 1 ? count : ''} Channel Guide${
          count !== 1 ? 's' : ''
        }`;
      },
    }),
    ...options,
  });
