import { Edit, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { GridCellModes, type GridCellModesModel, type GridSlotProps } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, type MouseEvent } from 'react';
import { SearchBar } from '~/components/forms/search-bar';
import { cancelExistingCells, type SelectedCellParams } from '~/hooks/data-grid';

declare module '@mui/x-data-grid-pro' {
  interface ToolbarPropsOverrides {
    cellMode: GridCellModes;
    cellModesModel?: GridCellModesModel;
    editable: boolean;
    loadingEdit: boolean;
    onAddRow: () => void;
    onSearch: (search: string) => void;
    search: string;
    selectedCellParams?: SelectedCellParams;
    setCellModesModel: (value: GridCellModesModel) => void;
  }
}

interface HandleChangeProps {
  cancel?: boolean;
}

export const SpreadsheetToolbar = ({
  cellMode,
  cellModesModel = {},
  editable,
  loadingEdit,
  onAddRow,
  onSearch,
  search,
  selectedCellParams,
  setCellModesModel,
}: GridSlotProps['toolbar']) => {
  const uneditableField = useMemo(
    () => (selectedCellParams && ['id', 'actions'].includes(selectedCellParams.field)) || false,
    [selectedCellParams],
  );

  const handleChange = useCallback(
    ({ cancel = false }: HandleChangeProps) =>
      () => {
        if (!selectedCellParams || uneditableField) return;

        const existingCells = cancelExistingCells(cellModesModel);
        const { id, field } = selectedCellParams;

        setCellModesModel({
          ...existingCells,
          [id]: {
            ...existingCells[id],
            [field]: {
              mode: cellMode === 'edit' ? GridCellModes.View : GridCellModes.Edit,
              ignoreModifications: cancel,
            },
          },
        });
      },
    [cellMode, cellModesModel, selectedCellParams, setCellModesModel, uneditableField],
  );

  const handleMouseDown = useCallback((event: MouseEvent) => event.preventDefault(), []);

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        display: 'flex',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {editable && (
          <>
            <LoadingButton
              disabled={loadingEdit || !selectedCellParams || uneditableField}
              loading={loadingEdit}
              loadingPosition="start"
              startIcon={cellMode === 'edit' || loadingEdit ? <Save /> : <Edit />}
              variant="outlined"
              onClick={handleChange({ cancel: false })}
              sx={{
                width: 75,
                '& circle': {
                  // something is interfering with the animation here
                  animation: 'none',
                },
              }}
              onMouseDown={handleMouseDown}
            >
              {cellMode === 'edit' || loadingEdit ? 'Save' : 'Edit'}
            </LoadingButton>
            <Button
              disabled={cellMode === GridCellModes.View}
              onClick={handleChange({ cancel: true })}
              onMouseDown={handleMouseDown}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={cellMode === GridCellModes.Edit}
              onClick={onAddRow}
              onMouseDown={handleMouseDown}
              sx={{ ml: 1 }}
              variant="outlined"
            >
              Add Row
            </Button>
          </>
        )}
      </Box>
      <SearchBar search={search} onChange={onSearch} />
    </Box>
  );
};
