import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { Box, Button, type DialogProps } from '@mui/material';
import { TreeView } from '@mui/x-tree-view';
import { useCallback, useState, type SyntheticEvent } from 'react';
import { useAddToRole } from '~/api/roles';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { useContentFolderContext } from '~/contexts';
import { type ContentFolderTree } from '~/graphql/scalars';
import { type RoleContentFolderList__Role as Role } from '../RoleContentFolderList.generated';
import { CheckboxTreeItem } from './';

const getDescendants = (contentFolderTree: ContentFolderTree, id: number) => {
  const getTreeNode = (treeNode: ContentFolderTree): ContentFolderTree | null => {
    if (treeNode.id === id) return treeNode;
    for (const child of treeNode.children) {
      const node = getTreeNode(child);
      if (node) return node;
    }
    return null;
  };

  const flatten = ({ children }: ContentFolderTree): ContentFolderTree[] =>
    children.reduce<ContentFolderTree[]>((acc, node) => [...acc, node, ...flatten(node)], []);

  const treeNode = getTreeNode(contentFolderTree);
  if (!treeNode) return [];

  return flatten(treeNode);
};

export interface AddContentFoldersDialogProps extends Omit<DialogProps, 'role'> {
  role: Role;
}

export const AddContentFoldersDialog = ({ role, ...props }: AddContentFoldersDialogProps) => {
  const { contentFolderTree } = useContentFolderContext();
  const [expanded, setExpanded] = useState<string[]>([String(contentFolderTree.id)]);
  const [selected, setSelected] = useState<string[]>(
    role.contentFolderRules.map((x) => String(x.contentFolder.id)),
  );

  const [add, { called }] = useAddToRole({
    variables: {
      roleId: role.id,
      contentFolderRules: selected.map((id) => ({ contentFolderId: parseInt(id) })),
    },
  });

  const close = () => props.onClose?.({}, 'backdropClick');

  const handleSelect = useCallback(
    (event: SyntheticEvent<Element, Event>, nodeIds: string[]) => {
      if ((event.target as Node).nodeName !== 'DIV') return;
      setSelected((current) => {
        const id = nodeIds.at(0);
        if (!id) return current;
        if (current.includes(id)) return current.filter((x) => x !== id);

        const descendantIds = getDescendants(contentFolderTree, parseInt(id)).map((x) => x.id);
        return [...current.filter((x) => !descendantIds.includes(parseInt(x))), id];
      });
    },
    [contentFolderTree],
  );

  const handleToggle = useCallback((event: SyntheticEvent<Element, Event>, nodeIds: string[]) => {
    if ((event.target as Node).nodeName === 'DIV') return;
    setExpanded(() => nodeIds);
  }, []);

  const renderTree = useCallback(
    (children: ContentFolderTree[], disabled: boolean) =>
      children.map((x) => (
        <CheckboxTreeItem disabled={disabled} key={x.id} nodeId={String(x.id)} label={x.name}>
          {renderTree(x.children, disabled || selected.includes(String(x.id)))}
        </CheckboxTreeItem>
      )),
    [selected],
  );

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Content Folders</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Folders to add to the <b>{role.name}</b> role.
        </DialogContentTitle>

        <Box>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={expanded}
            multiSelect
            onNodeSelect={handleSelect}
            onNodeToggle={handleToggle}
            selected={selected}
          >
            <CheckboxTreeItem nodeId={String(contentFolderTree.id)} label="All Folders">
              {renderTree(
                contentFolderTree.children,
                selected.includes(String(contentFolderTree.id)),
              )}
            </CheckboxTreeItem>
          </TreeView>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || !selected.length}
          onClick={() => {
            close();
            return add();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
