import { SwapHorizontalCircle } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStopImpersonate } from '~/api/users';
import { useAppContext } from '~/contexts/app';
import { useLink } from '~/hooks/link';

export const ImpersonationButton = () => {
  const { currentUser, impersonating } = useAppContext();

  const navigate = useNavigate();
  const link = useLink();

  const [stopImpersonate, { client }] = useStopImpersonate();

  const handleStopImpersonate = useCallback(async () => {
    await stopImpersonate();
    await client.resetStore();
    navigate(link('/dashboard'));
  }, [client, link, navigate, stopImpersonate]);

  if (!impersonating) return null;

  return (
    <Fab
      color="warning"
      variant="extended"
      onClick={handleStopImpersonate}
      sx={{
        position: 'fixed',
        bottom: (theme) => theme.spacing(3),
        right: (theme) => theme.spacing(3),
      }}
    >
      <SwapHorizontalCircle sx={{ mr: 1 }} />
      You are logged in as {currentUser.name}
    </Fab>
  );
};
