import { ApolloProvider } from '@apollo/client/react';
import { LicenseInfo } from '@mui/x-license-pro';
import { SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { ThemeWrapper } from '~/components/theme';
import { useAutoReloadServiceWorker } from '~/hooks/serviceWorker';
import { client } from '~/lib/apollo';
import { router } from '~/pages';

import '~/styles/index.css';

LicenseInfo.setLicenseKey(
  '36fd6d71db350a1b282e02c9cc4188d5Tz0xMDUxODAsRT0xNzY5Nzc3ODUzMDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

const App = () => {
  useAutoReloadServiceWorker();

  return (
    <StrictMode>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <ThemeWrapper>
            <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
              <Helmet defaultTitle="FanConnect CMS" titleTemplate="FanConnect CMS - %s" />
              <RouterProvider router={router} />
            </SnackbarProvider>
          </ThemeWrapper>
        </ApolloProvider>
      </HelmetProvider>
    </StrictMode>
  );
};

render(<App />, document.getElementById('root'));
