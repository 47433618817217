import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useCallback, type MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteChannelGuide } from '~/api/channel-guides/delete';
import { DeleteButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import type { ChannelGuideSettings__ChannelGuide as ChannelGuide } from '../../queries/settings.generated';

export interface ActionsProps {
  channelGuide: ChannelGuide;
  onShowMenu?: MouseEventHandler<HTMLButtonElement>;
}

export const Actions = ({ channelGuide }: ActionsProps) => {
  const { currentNetwork } = useAppContext();

  const navigate = useNavigate();

  const [confirm, confirmationProps] = useConfirmDialog();
  const [deleteChannelGuide] = useDeleteChannelGuide();

  const handleDelete = useCallback(async () => {
    if (!(await confirm())) return;
    await deleteChannelGuide({
      variables: { input: { channelGuideIds: [channelGuide.id], networkId: currentNetwork.id } },
    });
    navigate('../..');
  }, [channelGuide, confirm, currentNetwork, deleteChannelGuide, navigate]);

  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Tooltip title={channelGuide.canDestroy.reasons?.fullMessages.join(', ')}>
          <span>
            <DeleteButton disabled={!channelGuide.canDestroy.value} onClick={handleDelete} />
          </span>
        </Tooltip>
      </Box>
      <ConfirmDialog
        {...confirmationProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Channel Guide will remove it from Devices where it is being used."
        title="Delete Channel Guide"
      />
    </>
  );
};
