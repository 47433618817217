import { Button, CircularProgress } from '@mui/material';
import type { DeviceStatus } from '~/generated/graphql';
import type { DeviceList__FilterCount } from '../../queries/list.generated';
import { useDeviceFilters } from '../context';
import { StatusToggleContainer } from '../lib';

export const StatusToggle = ({
  filterCounts,
}: {
  filterCounts: DeviceList__FilterCount | undefined;
}) => {
  const { setFilter, filters } = useDeviceFilters();

  const healthyCount = filterCounts?.status.find((x) => x.filter === 'healthy')?.count || 0;
  const errorCount = filterCounts?.status.find((x) => x.filter === 'error')?.count || 0;

  const isActive = (desired: DeviceStatus[]) => {
    const selected = filters.status.items.filter((f) => f.selected).map((f) => f.value);

    if (selected.length !== desired.length) return false;
    return desired.every((status) => selected.includes(status));
  };
  return (
    <StatusToggleContainer>
      <Button
        size="small"
        variant="contained"
        onClick={() =>
          setFilter({
            field: 'status',
            items: [
              { label: 'Online', selected: false, value: 'HEALTHY' },
              { label: 'Offline', selected: false, value: 'ERROR' },
              { label: 'Warning', selected: false, value: 'WARN' },
            ],
          })
        }
        sx={{
          backgroundColor: isActive([]) ? 'common.white' : 'transparent',
          color: isActive([]) ? 'primary.main' : '#969696',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        All
      </Button>

      <Button
        size="small"
        variant="contained"
        onClick={() =>
          setFilter({
            field: 'status',
            items: [
              { label: 'Online', selected: true, value: 'HEALTHY' },
              { label: 'Offline', selected: false, value: 'ERROR' },
              { label: 'Warning', selected: false, value: 'WARN' },
            ],
            open: true,
          })
        }
        sx={{
          backgroundColor: isActive(['HEALTHY']) ? 'common.white' : 'transparent',
          color: isActive(['HEALTHY']) ? 'primary.main' : '#969696',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        Online ({filterCounts ? healthyCount : <CircularProgress size={12} color="inherit" />})
      </Button>

      <Button
        size="small"
        variant="contained"
        onClick={() =>
          setFilter({
            field: 'status',
            items: [
              { label: 'Online', selected: false, value: 'HEALTHY' },
              { label: 'Offline', selected: true, value: 'ERROR' },
              { label: 'Warning', selected: false, value: 'WARN' },
            ],
            open: true,
          })
        }
        sx={{
          backgroundColor: isActive(['ERROR']) ? 'common.white' : 'transparent',
          color: isActive(['ERROR']) ? 'primary.main' : '#969696',
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        Offline ({filterCounts ? errorCount : <CircularProgress size={12} color="inherit" />})
      </Button>
    </StatusToggleContainer>
  );
};
