import { Cloud } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useSearchParams } from 'react-router-dom';
import { useSyncDataSource } from '~/api/data-sources';
import { usePaginationParams } from '~/hooks/pagination';
import { useDataSource } from '../context';

export const DataSourceSyncButton = () => {
  const dataSource = useDataSource();
  const [syncDataSource, { loading }] = useSyncDataSource();
  const { page, perPage } = usePaginationParams();
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search') ?? '';

  return (
    <LoadingButton
      loading={loading}
      loadingPosition="start"
      color="primary"
      disabled={loading}
      onClick={() =>
        void syncDataSource({ variables: { input: { id: dataSource.id }, page, perPage, search } })
      }
      startIcon={<Cloud />}
      variant="contained"
    >
      Sync
    </LoadingButton>
  );
};
