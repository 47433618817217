import { useQuery } from '@apollo/client';
import { AddCircle, Dvr } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useSearchParams } from 'react-router-dom';
import { RouterTabs } from '~/components/RouterTabs';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { PageContainer } from '~/components/page-layout';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { matches } from '~/lib/search';
import { EditChannelDialog } from '../components/EditDialog';
import { NewChannelDialog } from '../components/NewDialog';
import { ChannelActions, TabWrapper } from '../lib/styles';
import { ChannelListDocument, type ChannelList__Channel } from '../queries/list.generated';
import { Table } from './components/table';

export const ChannelList = () => {
  const { currentNetwork } = useAppContext();

  const [searchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const [currentChannel, setCurrentChannel] = useState<ChannelList__Channel>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const [newChannelDialogOpen, setNewChannelDialogOpen] = useState(false);

  const { data, loading } = useQuery(ChannelListDocument, {
    variables: { networkId: currentNetwork.id },
  });

  const channels = useMemo(
    () => data?.network?.channels.filter((channel) => matches(search, channel.name)),
    [data?.network?.channels, search],
  );

  if (!loading && data?.network == null) return <Navigate replace to="/not-found" />;

  return (
    <>
      <Helmet title="Channels" />
      <Toolbar
        actions={
          <Button
            color="primary"
            onClick={() => setNewChannelDialogOpen(true)}
            startIcon={<AddCircle />}
            variant="contained"
          >
            New Channel
          </Button>
        }
        titleIcon={<Dvr />}
        titleText="Channels"
      ></Toolbar>
      <TabWrapper>
        <RouterTabs
          sx={{ padding: 0, paddingTop: 1 }}
          tabs={[
            { label: 'Channels', to: '' },
            { label: 'Channel Guides', to: '../channel-guides' },
          ]}
          variant="standard"
        />
      </TabWrapper>
      <PageContainer>
        {channels && (
          <>
            <ChannelActions>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <SearchBar placeholder="Search Channels" search={search} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Count selectedCount={0} totalCount={channels.length} thing="channel" />
              </Box>
            </ChannelActions>
            <Table
              channels={channels}
              loading={loading}
              onEditChannel={(channel) => {
                setCurrentChannel(channel);
                setEditDialogOpen(true);
              }}
            />
          </>
        )}
      </PageContainer>
      {newChannelDialogOpen && (
        <NewChannelDialog fullWidth close={() => setNewChannelDialogOpen(false)} open />
      )}
      {currentChannel && (
        <EditChannelDialog
          channel={currentChannel}
          close={() => setEditDialogOpen(false)}
          open={editDialogOpen}
        />
      )}
    </>
  );
};
