import { Box, Button, type DialogProps } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useAddToRole } from '~/api/roles';
import { Count } from '~/components/count';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { searchRegex } from '~/lib/search';
import type { RoleUserList__Role, RoleUserList__User } from '../RoleUserList.generated';
import { UserTable } from './UserTable';

export interface AddUsersDialogProps extends Omit<DialogProps, 'role'> {
  role: RoleUserList__Role;
  users: readonly RoleUserList__User[];
}

export const AddUsersDialog = ({ role, users, ...props }: AddUsersDialogProps) => {
  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [addUsers, { called }] = useAddToRole({
    variables: { roleId: role.id, userRoles: selectedIds.map((id) => ({ userId: id as number })) },
  });

  const roleUserIds = new Set(role.users.map((user) => user.id));

  const searchReg = searchRegex(search);

  const rows = users.filter(
    (user) =>
      !roleUserIds.has(user.id) && (searchReg.test(user.name) || searchReg.test(user.email)),
  );

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Users</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Users to add to the <b>{role.name}</b> role.
        </DialogContentTitle>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Users" search={search} />
          <Count selectedCount={selectedIds.length} totalCount={rows.length} thing="user" />
        </Box>

        <Box>
          <UserTable
            autoHeight={false}
            checkboxSelection
            initialState={{ columns: { columnVisibilityModel: { actions: false } } }}
            onRowSelectionModelChange={setSelectedIds}
            role={role}
            rows={rows}
            rowSelectionModel={selectedIds}
            slots={{
              columnResizeIcon: () => null,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No users exist
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || !selectedIds.length}
          onClick={() => {
            close();
            return addUsers();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
