import { useCallback, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import type {
  DeviceArch,
  DeviceGroupNew as DeviceGroup,
  DeviceKind,
  DeviceStatus,
} from '~/generated/graphql';
import { allUnselectedState, useDeviceFiltersInitialState, type useDeviceFiltersParams } from './';

export interface DeviceFilterItems<T> {
  label: string;
  selected: boolean;
  value: T;
  count?: number;
}

export interface DeviceFilter<T> {
  items: DeviceFilterItems<T>[];
  open: boolean;
}

export interface DeviceFilters {
  arch: DeviceFilter<DeviceArch>;
  demo: DeviceFilter<boolean>;
  displayOn: DeviceFilter<boolean>;
  groups: DeviceFilter<number>;
  internal: DeviceFilter<boolean>;
  kind: DeviceFilter<DeviceKind>;
  silence: DeviceFilter<boolean>;
  status: DeviceFilter<DeviceStatus>;
  tags: DeviceFilter<string>;
}

export interface DevicePanel {
  open: boolean;
}

export interface DeviceFiltersState {
  filters: DeviceFilters;
  panel: DevicePanel;
}

export type DeviceFiltersAction =
  | { type: 'CLEAR_FILTERS' }
  | ({ type: 'SET_FILTER'; keepPage?: boolean; open?: boolean } & {
      [K in keyof DeviceFilters]: { field: K; items: DeviceFilters[K]['items'] };
    }[keyof DeviceFilters])
  | { type: 'TOGGLE_FILTER'; field: keyof DeviceFilters }
  | { type: 'TOGGLE_PANEL' };

export const useDeviceFiltersReducer = (
  params: ReturnType<typeof useDeviceFiltersParams>,
  deviceTags: readonly string[] | undefined,
  deviceGroups: ReadonlyArray<Pick<DeviceGroup, 'id' | 'name'>> | undefined,
) => {
  const [_, setSearchParams] = useSearchParams();

  const initialState = useDeviceFiltersInitialState(params);

  const reducer = useCallback(
    (state: DeviceFiltersState, action: DeviceFiltersAction): DeviceFiltersState => {
      switch (action.type) {
        case 'CLEAR_FILTERS': {
          setSearchParams((params) => {
            Object.keys(state.filters).forEach((x) => params.delete(x));
            params.set('page', '1');
            return params;
          });

          return allUnselectedState;
        }

        case 'SET_FILTER': {
          const {
            field,
            items,
          }: { field: keyof DeviceFilters; items: DeviceFilterItems<boolean | string | number>[] } =
            action;
          const { keepPage = false } = action;
          const selected = items.filter((x) => x.selected);
          setSearchParams((params) => {
            selected.length > 0
              ? params.set(field, JSON.stringify(selected.map((x) => x.value)))
              : params.delete(field);
            if (!keepPage) params.set('page', '1'); // reset pagination when filters change
            return params;
          });
          return {
            ...state,
            filters: {
              ...state.filters,
              [field]: {
                ...state.filters[field],
                ...(action.open != null && { open: action.open }),
                items,
              },
            },
          };
        }

        case 'TOGGLE_FILTER': {
          return {
            ...state,
            filters: {
              ...state.filters,
              [action.field]: {
                ...state.filters[action.field],
                open: !state.filters[action.field].open,
              },
            },
          };
        }

        case 'TOGGLE_PANEL': {
          return {
            ...state,
            panel: { ...state.panel, open: !state.panel.open },
          };
        }

        default: {
          return state;
        }
      }
    },
    [setSearchParams],
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!deviceTags) return;
    if (deviceTags.join() === state.filters.tags.items.map((x) => x.value).join()) return;
    dispatch({
      type: 'SET_FILTER',
      field: 'tags',
      items: deviceTags.map((x) => ({
        label: x,
        selected: params.tags.includes(x),
        value: x,
      })),
      keepPage: true,
      open: params.tags.length > 0,
    });
  }, [deviceTags, params.tags, state.filters.tags.items]);

  useEffect(() => {
    if (!deviceGroups) return;
    if (
      deviceGroups.map((x) => x.id).join() === state.filters.groups.items.map((x) => x.value).join()
    )
      return;
    dispatch({
      type: 'SET_FILTER',
      field: 'groups',
      items: deviceGroups.map((x) => ({
        label: x.name,
        selected: params.groups.includes(x.id),
        value: x.id,
      })),
      keepPage: true,
      open: params.groups.length > 0,
    });
  }, [deviceGroups, params.groups, state.filters.groups.items]);

  return [state, dispatch] as const;
};
