import { Delete, VisibilityOff } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridCellModes,
  type GridColDef,
  type GridEventListener,
} from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreateDataItem, useDeleteDataItem, useUpdateDataItem } from '~/api/data-items';
import { ActionButton } from '~/components/button';
import { useDataGridEditing } from '~/hooks/data-grid';
import { useConfirmDialog } from '~/hooks/dialogs';
import { usePaginationParams } from '~/hooks/pagination';
import type { DataSourceGet__DataSource as DataSource } from '../queries/get.generated';

interface DataType {
  id: number;
  __enabled__?: string;
  __internalId__?: number;
  __preventDestroyReason__?: string;
  [key: string]: string | number | undefined;
}

export const useSpreadsheetUtils = (
  dataSource: DataSource | undefined,
  highlightedId: number | undefined,
) => {
  const { page, perPage } = usePaginationParams();

  const {
    cellMode,
    cellModesModel,
    handleCellFocus,
    handleCellKeyDown,
    selectedCellParams,
    setCellModesModel,
    setSelectedCellParams,
  } = useDataGridEditing();

  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  const [createDataItem] = useCreateDataItem();
  const [deleteDataItem] = useDeleteDataItem();
  const [updateDataItem] = useUpdateDataItem();

  const [newRow, setNewRow] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);

  const blankRow = useMemo(
    () => ({
      ...Object.fromEntries(dataSource?.keys.map((x) => [x, '']) || []),
      __enabled__: 'true',
    }),
    [dataSource?.keys],
  );

  const addRowField = useMemo(
    () => dataSource?.foreignIdName || dataSource?.keys.at(0) || '',
    [dataSource],
  );

  const handleDelete = useCallback(
    async (id: number | undefined) => {
      if (!id) {
        setNewRow(() => false);
        return;
      }
      if (!(await confirmDelete())) return;
      void deleteDataItem({ variables: { input: { id }, page, perPage } });
    },
    [confirmDelete, deleteDataItem, page, perPage],
  );

  const processRowUpdate = useCallback(
    async (newRow: DataType, oldRow: DataType) => {
      setLoadingEdit(true);

      try {
        const {
          id: displayId,
          __internalId__,
          __enabled__,
          __preventDestroyReason__,
          ...data
        } = newRow;

        if (!__internalId__) {
          if (!dataSource) return oldRow;

          const response = await createDataItem({
            variables: {
              input: {
                dataSourceId: dataSource.id,
                data,
              },
              page,
              perPage,
            },
          });

          setNewRow(() => false);

          if (!response.data?.createDataItem) {
            setSelectedCellParams({ id: '1', field: dataSource.keys.at(0) || '' });
            return oldRow;
          }

          const { dataItem } = response.data.createDataItem;
          return {
            ...dataItem.data,
            id: displayId,
            __enabled__: String(dataItem.enabled),
            __internalId__: dataItem.id,
            __preventDestroyReason__: dataItem.canDestroy.reasons?.fullMessages.join(),
          };
        }

        const response = await updateDataItem({
          variables: {
            id: __internalId__,
            data,
          },
        });

        if (response.errors) return oldRow;

        return {
          ...response.data?.updateDataItem?.dataItem.data,
          id: displayId,
          __internalId__,
          __preventDestroyReason__:
            response.data?.updateDataItem?.dataItem.canDestroy.reasons?.fullMessages.join(),
        };
      } finally {
        setLoadingEdit(false);
      }
    },
    [createDataItem, dataSource, page, perPage, setSelectedCellParams, updateDataItem],
  );

  // use default MUI data grid behavior unless we are clicking the cancel button
  const handleCellEditStop = useCallback<GridEventListener<'cellEditStop'>>((params, event) => {
    if (
      params.reason !== 'cellFocusOut' ||
      !(event instanceof MouseEvent) ||
      !(event.target instanceof HTMLButtonElement)
    )
      return;
    if (event.target.innerText === 'Cancel') event.defaultMuiPrevented = true;
  }, []);

  const rows: DataType[] = useMemo(() => {
    if (!dataSource) return [];
    const filteredRows: Partial<DataType>[] = dataSource.items.nodes.flatMap((x) =>
      x.data
        ? [
            {
              ...x.data,
              __internalId__: x.id,
              __enabled__: String(x.enabled),
              __preventDestroyReason__: x.canDestroy.reasons?.fullMessages.join(),
            },
          ]
        : [],
    );
    if (newRow) filteredRows.push(blankRow);
    return filteredRows.map((x, i) => ({ ...x, id: i + 1 + perPage * (page - 1) }));
  }, [blankRow, dataSource, newRow, page, perPage]);

  const columns = useMemo(() => {
    if (!dataSource) return [];

    const cols: GridColDef<DataType>[] = [
      {
        align: 'center',
        field: 'id',
        headerName: '',
        maxWidth: 75,
        sortable: false,
      },
      ...dataSource.keys.map((x) => ({
        editable: dataSource.kind !== 'INTEGRATION',
        field: x,
        flex: 1,
        headerName: x,
        minWidth: 150,
        sortable: false,
      })),
    ];

    if (dataSource.kind !== 'INTEGRATION') {
      cols.push({
        field: 'actions',
        type: 'actions',
        getActions: ({ row }) => {
          const actions = [
            <GridActionsCellItem
              icon={
                <Tooltip title="This row is disabled">
                  <VisibilityOff fontSize="inherit" />
                </Tooltip>
              }
              key="0"
              label="disabled"
              sx={{
                visibility: row.__enabled__ === 'true' ? 'hidden' : 'visible',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            />,
          ];

          if (row.__internalId__) {
            actions.push(
              <ActionButton
                Icon={Delete}
                color="error"
                disabled={Boolean(row.__preventDestroyReason__)}
                key="1"
                onClick={() => handleDelete(row.__internalId__)}
                title={row.__preventDestroyReason__ || 'Delete row'}
              />,
            );
          }

          return actions;
        },
      });
    }
    return cols;
  }, [dataSource, handleDelete]);

  const handleAddRow = useCallback(() => {
    setNewRow(() => true);

    const id = rows.length + 1;

    setCellModesModel({
      ...cellModesModel,
      [id]: {
        [addRowField]: {
          mode: GridCellModes.Edit,
        },
      },
    });
  }, [addRowField, cellModesModel, rows.length, setCellModesModel]);

  const highlightedIndex = useMemo(
    () =>
      highlightedId
        ? rows.findIndex((x) => x.__internalId__ === highlightedId) + 1 + perPage * (page - 1)
        : null,
    [highlightedId, page, perPage, rows],
  );

  useEffect(() => {
    if (!dataSource || !newRow || !cellModesModel) return;
    if (!Object.keys(cellModesModel).includes(String(rows.length))) return;
    const cell = cellModesModel[rows.length][addRowField];
    if (cell.mode === GridCellModes.View && cell.ignoreModifications) {
      setNewRow(() => false);
      setSelectedCellParams({ id: '1', field: dataSource.keys.at(0) || '' });
    }
  }, [
    addRowField,
    cellMode,
    cellModesModel,
    dataSource,
    newRow,
    rows.length,
    setSelectedCellParams,
  ]);

  useEffect(() => {
    if (!dataSource || selectedCellParams) return;
    setSelectedCellParams({ id: '1', field: dataSource.keys.at(0) || '' });
  }, [dataSource, selectedCellParams, setSelectedCellParams]);

  return {
    cellMode,
    cellModesModel,
    columns,
    confirmDeleteProps,
    handleAddRow,
    handleCellEditStop,
    handleCellFocus,
    handleCellKeyDown,
    highlightedId,
    highlightedIndex,
    loadingEdit,
    processRowUpdate,
    rows,
    selectedCellParams,
    setCellModesModel,
    setSelectedCellParams,
  };
};
