import { Box, useTheme } from '@mui/material';
import { SaveButton } from '~/components/button';
import { FormLayout } from '~/components/settings';
import type {
  ChannelGuideSettings__Channel as Channel,
  ChannelGuideSettings__ChannelGuide as ChannelGuide,
} from '../../queries/settings.generated';
import { useSettingsForm } from '../lib/settings';

export type ChannelGuideSettingsProps = {
  channelGuide: ChannelGuide;
  channels: readonly Channel[];
};

export const SettingsForm = (channelGuideSettings: ChannelGuideSettingsProps) => {
  const { channelGuide } = channelGuideSettings;

  const theme = useTheme();

  const { formik, generalSettings } = useSettingsForm(channelGuideSettings);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(1),
          justifyContent: 'flex-end',
          paddingBottom: theme.spacing(1),
          [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
          },
        }}
      >
        <SaveButton
          disabled={
            !channelGuide.canUpdate.value || !formik.isValid || formik.isSubmitting || !formik.dirty
          }
          type="submit"
        >
          Save
        </SaveButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
        <FormLayout formHeading="General" formData={generalSettings} />
      </Box>
    </form>
  );
};
