import { Box, Button, styled, useTheme } from '@mui/material';
import type { ComponentProps } from 'react';

export const FilterAccordionContainer = ({ children, ...props }: ComponentProps<typeof Box>) => {
  const theme = useTheme();
  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        overflowY: 'auto',
        height: 'calc(100vh - 120px)',
        padding: theme.spacing(0, 2, 8, 2),
        [theme.breakpoints.down('sm')]: {
          height: 'calc(100vh - 475px)',
        },
      }}
    >
      {children}
    </Box>
  );
};

// TODO: Move to Footer component once all list pages have been updated to use the new filter UI
export const ClearAllButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  fontSize: '12px',
}));

export const FilterListSidebar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRight: '1px solid #e6e6e6',
  width: '250px',
  overflow: 'hidden',
  transform: 'translateX(0)',
  transition: 'transform 0.1s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    transform: 'translateY(0)',
    height: 'calc(100vh - 425px)',
    width: '100%',
    borderBottom: '1px solid #e6e6e6',
    borderRight: 0,
  },
  '&.hidden': {
    transform: 'translateX(-100%)',
    borderRight: 'none',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateY(-100%)',
    },
  },
}));

export const FilterContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));

// TODO: Remove me once all list pages have been updated to use the new filter UI
export const LegacyFilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
}));
// TODO: Remove me once all list pages have been updated to use the new filter UI
export const ClearAllContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: '123px',
}));
