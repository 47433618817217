import {
  DesktopWindows,
  DeveloperBoard,
  DoNotStep,
  Hub,
  RocketLaunch,
  Router,
  Sell,
  VisibilityOff,
  Wifi,
} from '@mui/icons-material';
import {
  FilterAccordionContainer,
  FilterCollapsibleList,
  FilterContainer,
  FilterFooter,
  FilterHeader,
} from '~/components/Filter';
import { useAppContext } from '~/contexts';
import type { DeviceList__FilterCount } from '../../queries/list.generated';
import { useDeviceFilters } from '../context';

export interface FilterAccordionProps {
  filterCounts: DeviceList__FilterCount | undefined;
}

export const FilterAccordion = ({ filterCounts }: FilterAccordionProps) => {
  const { currentUser } = useAppContext();
  const { clearFilters, filters, setFilter, toggleFilter } = useDeviceFilters();

  return (
    <FilterContainer>
      <FilterHeader />
      <FilterAccordionContainer>
        {filters.tags.items.length > 0 && (
          <FilterCollapsibleList
            Icon={Sell}
            expanded={filters.tags.open}
            items={filters.tags.items.map((item) => {
              const matched = filterCounts?.tags.find((c) => c.filter === item.value);
              return { ...item, count: matched?.count || 0 };
            })}
            label="Tags"
            loading={!filterCounts}
            onClick={(_, items) => setFilter({ field: 'tags', items })}
            onExpand={() => toggleFilter('tags')}
          />
        )}
        {filters.groups.items.length > 0 && (
          <FilterCollapsibleList
            Icon={Hub}
            expanded={filters.groups.open}
            items={filters.groups.items.map((item) => {
              const matched = filterCounts?.groups.find((c) => c.filter === String(item.value));
              return { ...item, count: matched?.count || 0 };
            })}
            label="Groups"
            loading={!filterCounts}
            onClick={(_, items) => setFilter({ field: 'groups', items })}
            onExpand={() => toggleFilter('groups')}
          />
        )}
        <FilterCollapsibleList
          Icon={Router}
          expanded={filters.kind.open}
          items={filters.kind.items.map((item) => {
            const matched = filterCounts?.kind.find((c) => c.filter.toUpperCase() === item.value);
            return { ...item, count: matched?.count || 0 };
          })}
          label="Type"
          loading={!filterCounts}
          onClick={(_, items) => setFilter({ field: 'kind', items })}
          onExpand={() => toggleFilter('kind')}
        />
        {currentUser.admin && (
          <FilterCollapsibleList
            Icon={DeveloperBoard}
            expanded={filters.arch.open}
            items={filters.arch.items.map((item) => {
              const matched = filterCounts?.arch.find((c) => c.filter === item.value);
              return { ...item, count: matched?.count || 0 };
            })}
            label="Arch"
            loading={!filterCounts}
            onClick={(_, items) => setFilter({ field: 'arch', items })}
            onExpand={() => toggleFilter('arch')}
          />
        )}
        <FilterCollapsibleList
          Icon={Wifi}
          expanded={filters.status.open}
          items={filters.status.items.map((item) => {
            const matched = filterCounts?.status.find((c) => c.filter.toUpperCase() === item.value);
            return { ...item, count: matched?.count || 0 };
          })}
          label="Status"
          loading={!filterCounts}
          onClick={(_, items) => setFilter({ field: 'status', items })}
          onExpand={() => toggleFilter('status')}
        />
        <FilterCollapsibleList
          Icon={DesktopWindows}
          expanded={filters.displayOn.open}
          items={filters.displayOn.items.map((item) => {
            const matched = filterCounts?.displayOn.find((c) => c.filter === String(item.value));
            return { ...item, count: matched?.count || 0 };
          })}
          label="Display"
          loading={!filterCounts}
          onClick={(_, items) => setFilter({ field: 'displayOn', items })}
          onExpand={() => toggleFilter('displayOn')}
        />
        <FilterCollapsibleList
          Icon={DoNotStep}
          expanded={filters.silence.open}
          items={filters.silence.items.map((item) => {
            const matched = filterCounts?.silence.find((c) => c.filter === String(item.value));
            return { ...item, count: matched?.count || 0 };
          })}
          label="Silenced"
          loading={!filterCounts}
          onClick={(_, items) => setFilter({ field: 'silence', items })}
          onExpand={() => toggleFilter('silence')}
        />
        {currentUser.admin && (
          <>
            <FilterCollapsibleList
              Icon={VisibilityOff}
              expanded={filters.internal.open}
              items={filters.internal.items.map((item) => {
                const matched = filterCounts?.internal.find((c) => c.filter === String(item.value));
                return { ...item, count: matched?.count || 0 };
              })}
              label="Internal"
              loading={!filterCounts}
              onClick={(_, items) => setFilter({ field: 'internal', items })}
              onExpand={() => toggleFilter('internal')}
            />
            <FilterCollapsibleList
              Icon={RocketLaunch}
              expanded={filters.demo.open}
              items={filters.demo.items.map((item) => {
                const matched = filterCounts?.demo.find((c) => c.filter === String(item.value));
                return { ...item, count: matched?.count || 0 };
              })}
              label="Demo"
              loading={!filterCounts}
              onClick={(_, items) => setFilter({ field: 'demo', items })}
              onExpand={() => toggleFilter('demo')}
            />
          </>
        )}
      </FilterAccordionContainer>
      <FilterFooter handleClear={clearFilters} />
    </FilterContainer>
  );
};
