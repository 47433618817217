import { useMediaQuery, useTheme } from '@mui/material';
import { theme } from '~/components/theme';

export const isSmDown = () => {
  return window.innerWidth <= theme.breakpoints.values.sm;
};

export const useResponsive = () => {
  const theme = useTheme();
  return {
    isSmDown: useMediaQuery(theme.breakpoints.down('sm')),
    isMdDown: useMediaQuery(theme.breakpoints.down('md')),
    isLgDown: useMediaQuery(theme.breakpoints.down('lg')),
  };
};
