import { useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState, type FocusEvent } from 'react';
import { DataGridPagination } from '~/components/data-grid';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { useAppContext } from '~/contexts';
import { useTotalCount } from '~/hooks/pagination';
import {
  DataItemCellSelectGetDocument,
  type DataItemCellSelect__DataItemListed as DataItem,
  type DataItemCellSelect__DataSourceListed as DataSourceListed,
} from '../DataItemCellSelect.generated';
import {
  SpreadsheetyDataGrid,
  useDataSourceTable,
  type DataItemData,
  type SelectedCellParams,
} from '../lib';
import { DataItemCellSelectToolbar } from './';

export interface DataItemCellSelectModalProps {
  dataItem: DataItem | null;
  dataItemKey: string | undefined;
  dataSources: ReadonlyArray<DataSourceListed>;
  onChange: (selectedCell: SelectedCellParams | undefined) => void;
  onClose: () => void;
}

export const DataItemCellSelectModal = ({
  dataItem,
  dataItemKey,
  dataSources,
  onChange,
  onClose,
}: DataItemCellSelectModalProps) => {
  const { currentNetwork } = useAppContext();
  const [selectedCellParams, setSelectedCellParams] = useState<SelectedCellParams | undefined>(
    dataItem && dataItemKey ? { id: String(dataItem.id), field: dataItemKey } : undefined,
  );
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(
    dataItem?.source.id ?? dataSources.at(0)?.id ?? null,
  );
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [getDataSource, selectedSourceQuery] = useLazyQuery(DataItemCellSelectGetDocument);

  useEffect(() => {
    if (!selectedSourceId) return;
    void getDataSource({
      variables: {
        dataSourceId: selectedSourceId,
        networkId: currentNetwork.id,
        orderFirst: dataItem?.id,
        page,
        perPage: 50,
        search,
      },
    });
  }, [currentNetwork.id, dataItem?.id, getDataSource, page, search, selectedSourceId]);

  const onPaginationModelChange = useCallback(
    ({ page }: GridPaginationModel) => {
      setPage(page + 1);
    },
    [setPage],
  );

  const dataSource = useMemo(
    () => selectedSourceQuery.data?.network?.dataSource,
    [selectedSourceQuery],
  );

  const handleCellFocus = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      const { currentTarget } = event;
      const row = currentTarget.parentElement;
      if (!row) return;

      const id = row.dataset.id;
      const field = currentTarget.dataset.field;
      const data: DataItemData | null | undefined = dataSource?.items.nodes.find(
        (x) => String(x.id) === id,
      )?.data;
      if (!id || !field || !data || !data[field]) return;

      setSelectedCellParams({ id, field, value: data[field] });
    },
    [dataSource?.items.nodes],
  );

  const { columns, rows } = useDataSourceTable(dataSource, selectedCellParams);
  const totalCount = useTotalCount(dataSource?.items.pageInfo.nodesCount);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Select Cell</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentTitle>Select one cell from a Data Source.</DialogContentTitle>
        <DataItemCellSelectToolbar
          dataSources={dataSources}
          onSearch={(x) => setSearch(x)}
          onSourceSelect={(x) => setSelectedSourceId(() => x)}
          rowCount={rows.length}
          search={search}
          selectedSourceId={selectedSourceId}
        />
        <SpreadsheetyDataGrid
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 50, page: 0 },
            },
          }}
          loading={selectedSourceQuery.loading}
          onPaginationModelChange={onPaginationModelChange}
          pagination
          paginationMode="server"
          paginationModel={{ page: page - 1, pageSize: 50 }}
          rowCount={totalCount}
          rows={rows}
          slots={{ pagination: DataGridPagination }}
          slotProps={{
            cell: { onFocus: handleCellFocus },
            loadingOverlay: { variant: 'skeleton' },
            pagination: { rowsPerPageOptions: [] },
          }}
          sx={{
            '--DataGrid-overlayHeight': '300px',
            height: '60vh',
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={
            (!dataItem && !dataItemKey && !selectedCellParams) ||
            (dataItem?.id === selectedCellParams?.id && dataItemKey === selectedCellParams?.field)
          }
          onClick={() => {
            onChange(selectedCellParams);
            onClose();
          }}
          type="submit"
          variant="contained"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
