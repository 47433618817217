import { useQuery } from '@apollo/client';
import { FilterList } from '@mui/icons-material';
import { Box, Button, Hidden } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Count } from '~/components/count';
import { SearchBar } from '~/components/forms/search-bar';
import { useAppContext } from '~/contexts';
import { usePaginationParams, useTotalCount } from '~/hooks/pagination';
import { Filter, Table } from './components';
import { useFilterParams } from './lib';
import { NetworkAuditLogsDocument } from './queries/list.generated';
import { AuditLogActions } from './styles';

interface AuditLogListProps {
  channelGuideId?: number;
  contentItemId?: number;
  dataSourceId?: number;
  deviceGroupId?: number;
  deviceId?: number;
  eventTypeId?: number;
  layoutId?: number;
  mediaItemId?: number;
  playlistId?: number;
  recordTypes?: string[];
  roleId?: number;
  showId?: number;
  tvBrandId?: number;
  userId?: number;
}

export const AuditLogList = ({
  channelGuideId,
  contentItemId,
  dataSourceId,
  deviceGroupId,
  deviceId,
  eventTypeId,
  layoutId,
  mediaItemId,
  playlistId,
  recordTypes = [],
  roleId,
  showId,
  tvBrandId,
  userId,
}: AuditLogListProps) => {
  const { currentNetwork } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search')?.trim() ?? '';

  const { usersParams: auditUsers } = useFilterParams();
  const [showFilters, setShowFilters] = useState(auditUsers.length > 0);

  const { page, perPage } = usePaginationParams({ perPage: 50 });

  const { data, loading } = useQuery(NetworkAuditLogsDocument, {
    variables: {
      channelGuideId,
      contentItemId,
      dataSourceId,
      deviceId,
      deviceGroupId,
      eventTypeId,
      layoutId,
      mediaItemId,
      networkId: currentNetwork.id,
      page,
      perPage,
      playlistId,
      recordTypes,
      roleId,
      search,
      showId,
      tvBrandId,
      userId,
      userIds: auditUsers,
    },
  });

  const totalCount = useTotalCount(data?.network?.auditLogs.pageInfo.nodesCount);

  return (
    <div>
      <AuditLogActions>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <SearchBar
            onChange={(value) =>
              setSearchParams((params) => {
                params.set('search', value);
                params.set('page', '1');
                return params;
              })
            }
            placeholder="Search Audit Logs"
            search={search}
          />
          <Hidden smDown>
            <Button
              onClick={() => setShowFilters(() => !showFilters)}
              startIcon={<FilterList />}
              variant="outlined"
            >
              Filter
            </Button>
          </Hidden>
        </Box>
        <Count selectedCount={0} totalCount={totalCount || 0} thing="audit log" />
      </AuditLogActions>
      {showFilters && (
        <Hidden smDown>
          <Filter />
        </Hidden>
      )}
      <Table
        auditLogs={data?.network?.auditLogs.nodes ?? []}
        loading={loading}
        totalCount={totalCount}
      />
    </div>
  );
};
