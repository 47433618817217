import { useQuery } from '@apollo/client';
import { Tv } from '@mui/icons-material';
import { Navigate, useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { PageContainer } from '~/components/page-layout';
import { RouterBreadcrumbs } from '~/components/RouterBreadcrumbs';
import { ScrollableRouterTabs } from '~/components/RouterTabs';
import { Toolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { NetworkTvBrandAuditLogsDocument } from '../queries/AuditLogs.generated';
import { tabs } from './lib/tabs';

export const TVBrandAuditLogs = () => {
  const params = useParams<{ tvBrandId: string }>();
  const tvBrandId = parseInt(params.tvBrandId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(NetworkTvBrandAuditLogsDocument, {
    variables: { networkId: currentNetwork.id, tvBrandId },
  });

  const tvBrand = data?.network?.tvBrand;

  if (loading || !tvBrand) return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <>
      <Toolbar
        breadcrumbsLabel={<RouterBreadcrumbs />}
        titleIcon={<Tv />}
        titleText={tvBrand.name}
      />

      <ScrollableRouterTabs current="Audit Logs" tabs={tabs} />

      <PageContainer>
        <AuditLogList recordTypes={['TVBrand']} tvBrandId={tvBrandId} />
      </PageContainer>
    </>
  );
};
