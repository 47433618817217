import { Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { MainLayout } from '~/layouts';
import { PermissionFilter } from '../components/PermissionFilter';
import { ChannelGuideDetailPage } from './channel-guides/detail';
import { ChannelGuideAuditLogs } from './channel-guides/detail/AuditLogs';
import { ChannelGuideSettings } from './channel-guides/detail/settings';
import { ChannelGuideList } from './channel-guides/list';
import { ChannelList } from './channels/list';
import {
  ContentFolderView,
  ContentRoot,
  ContentRootRedirect,
  ContentSearch,
  EditApp,
  MediaItemAuditLogs,
  MediaItemDetails,
  MediaItemThumbnails,
  MediaItemVersions,
  MediaItemView,
} from './content';
import { CreatePassword } from './create-password';
import { Dashboard } from './dashboard';
import { DataSourcesList, EditDataSource } from './data-sources';
import { DataSourceAuditLogs } from './data-sources/AuditLogs';
import { ActivateDevice } from './devices/activate';
import { DeviceList } from './devices/list';
import { DeviceDashboard } from './devices/show';
import { DeviceAuditLogs } from './devices/show/AuditLogs';
import { DeviceCommands } from './devices/show/DeviceCommands';
import { DevicePreview } from './devices/show/preview';
import { DeviceSchedule } from './devices/show/schedule';
import { DeviceSettings } from './devices/show/settings';
import { Scheduler } from './events';
import { ScheduleShowDetailPage as EventScheduleShowDetailPage } from './events/show';
import { ScheduleShowPreviewPage as EventScheduleShowPreviewPage } from './events/show/preview';
import { ScheduleShowSettings as EventScheduleShowSettings } from './events/show/settings';
import { ForgotPassword } from './forgot-password';
import { EditLayout, LayoutAuditLogs, LayoutsList } from './layouts';
import { Login } from './login';
import { NotFound } from './not-found';
import { PlaylistDetailPage } from './playlists/detail';
import { PlaylistAuditLogs } from './playlists/detail/AuditLogs';
import { PlaylistPreviewPage } from './playlists/detail/preview';
import { PlaylistSettings } from './playlists/detail/settings';
import { PlaylistList } from './playlists/list';
import { Reporting } from './reporting';
import { ResetPassword } from './reset-password';
import { ScheduleShowDetails } from './schedules/detail';
import { ScheduleList } from './schedules/list';
import { NetworksSettings, SettingsBreadcrumb, UserList, UserProfile } from './settings';
import { AuditLogList, AuditLogsBreadcrumb } from './settings/AuditLogs';
import {
  DeviceGroupBreadcrumb,
  DeviceGroupListBreadcrumb,
  DeviceGroupsBreadcrumb,
  DeviceGroupList as SettingsDeviceGroupList,
} from './settings/device-groups';
import { DeviceGroupSettings } from './settings/device-groups/detail';
import { DeviceGroupAuditLogs } from './settings/device-groups/detail/AuditLogs';
import { DeviceGroupDevices } from './settings/device-groups/detail/devices';
import { EventTypeList } from './settings/event-types';
import {
  EventTypeBreadcrumb,
  EventTypeListBreadcrumb,
  EventTypesBreadcrumb,
} from './settings/event-types/components/breadcrumbs';
import { EventTypeSettings } from './settings/event-types/detail';
import { EventTypeAuditLogs } from './settings/event-types/detail/AuditLogs';
import { EventTypeEvents } from './settings/event-types/detail/events';
import { EventTypeShows } from './settings/event-types/detail/shows';
import { NewEventType } from './settings/event-types/new';
import { Fonts } from './settings/Fonts';
import {
  RoleAuditLogs,
  RoleBreadcrumb,
  RoleContentFolderList,
  RoleDataSourceList,
  RoleDeviceList,
  RoleList,
  RoleListBreadcrumb,
  RoleSettings,
  RoleShowList,
  RoleUserList,
  RolesBreadcrumb,
} from './settings/roles';
import { RolePlaylistList } from './settings/roles/RolePlaylistList/RolePlaylistList';
import {
  TVBrandBreadcrumb,
  TVBrandListBreadcrumb,
  TVBrandsBreadcrumb,
} from './settings/tv-brands/components/breadcrumbs';
import { TVBrandSettings } from './settings/tv-brands/detail';
import { TVBrandAuditLogs } from './settings/tv-brands/detail/AuditLogs';
import { TVBrandList } from './settings/tv-brands/list';
import { NewTVBrand } from './settings/tv-brands/new';
import {
  UserBreadcrumb,
  UserListBreadcrumb,
  UserSettings,
  UsersBreadcrumb,
} from './settings/users';
import { UserAuditLogs } from './settings/users/detail/AuditLogs';
import { ShowPreviewPage } from './shows/components/preview';
import { ShowDetailPage } from './shows/detail';
import { ShowDetailAdvancedPage } from './shows/detail/advanced';
import { ShowAuditLogs } from './shows/detail/AuditLogs';
import { ShowSettings } from './shows/detail/settings';
import { ShowList } from './shows/list';
import { StudioEdit, StudioLayout, StudioList } from './studio';
import { CreateTemplate, TemplateList } from './templates';
import { TemplateLayout } from './templates/detail/components/layout';
import { TemplateConfiguration } from './templates/detail/configuration';
import { TemplateSettings } from './templates/detail/settings';
import { TemplateVersions } from './templates/detail/versions';

Sentry.init({
  dsn: 'https://1c9857e7842b483cab4f3121b8f4c9bf@o1130152.ingest.sentry.io/6178115',
  enabled: import.meta.env.PROD,
  environment: window.location.hostname.includes('staging') ? 'staging' : 'production',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  release: import.meta.env.SENTRY_RELEASE,
  tracesSampleRate: 0.03, // 3% for now
});

export const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route>
      <Route path="create-password/:token" element={<CreatePassword />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="login" element={<Login />} />

      <Route path="/" element={<MainLayout />}>
        <Route path="activate" />

        <Route path="networks/:networkId">
          <Route index element={<Navigate replace to="dashboard" />} />

          <Route
            path="activate"
            element={<Navigate replace to={`../devices/activate${location.search}`} />}
          />

          <Route path="dashboard" element={<Dashboard />} />

          <Route path="channel-guides" element={<PermissionFilter permission="canManage" />}>
            <Route index element={<ChannelGuideList />} />
            <Route path=":channelGuideId">
              <Route index element={<Navigate to="channels" replace />} />
              <Route path="channels" element={<ChannelGuideDetailPage />} />
              <Route path="settings" element={<ChannelGuideSettings />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<ChannelGuideAuditLogs />} />
              </Route>
            </Route>
          </Route>

          <Route path="channels" element={<PermissionFilter permission="canManage" />}>
            <Route index element={<ChannelList />} />
          </Route>

          <Route
            path="data-sources"
            element={<PermissionFilter permission="canAccessDataSources" />}
          >
            <Route index element={<DataSourcesList />} />
            <Route path=":dataSourceId">
              <Route index element={<Navigate replace to="edit" />} />
              <Route path="edit" element={<EditDataSource />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<DataSourceAuditLogs />} />
              </Route>
            </Route>
          </Route>

          <Route path="devices" element={<PermissionFilter permission="canAccessDevices" />}>
            <Route index element={<DeviceList />} />
            <Route element={<PermissionFilter permission="canActivateDevices" />}>
              <Route path="activate" element={<ActivateDevice />} />
            </Route>
            <Route path=":deviceId">
              <Route index element={<Navigate replace to="details" />} />
              <Route path="details" element={<DeviceDashboard />} />
              <Route path="preview" element={<DevicePreview />} />
              <Route path="schedule" element={<DeviceSchedule />} />
              <Route path="settings" element={<DeviceSettings />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<DeviceAuditLogs />} />
                <Route path="commands" element={<DeviceCommands />} />
              </Route>
            </Route>
          </Route>

          <Route path="events" element={<PermissionFilter permission="canAccessScheduler" />}>
            <Route index element={<Scheduler />} />
            <Route path="shows/:showId">
              <Route path="design" element={<EventScheduleShowDetailPage />} />
              <Route path="preview" element={<EventScheduleShowPreviewPage />} />
              <Route path="settings" element={<EventScheduleShowSettings />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<ShowAuditLogs />} />
              </Route>
            </Route>
          </Route>

          <Route path="layouts" element={<PermissionFilter permission="canManage" />}>
            <Route index element={<LayoutsList />} />
            <Route path=":layoutId">
              <Route index element={<Navigate replace to="design" />} />
              <Route path="design" element={<EditLayout />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<LayoutAuditLogs />} />
              </Route>
            </Route>
          </Route>

          <Route path="content" element={<ContentRoot />}>
            <Route index element={<ContentRootRedirect />} />
            <Route path="search" element={<ContentSearch />} />
            <Route path=":contentFolderId">
              <Route index element={<ContentFolderView />} />
              <Route path="apps/:appId" element={<EditApp />}>
                <Route index element={<Navigate replace to="settings" />} />
                <Route path="details" />
                <Route path="settings" />
                <Route element={<PermissionFilter permission="canManage" />}>
                  <Route path="audit-logs" />
                </Route>
              </Route>
              <Route path="media/:mediaItemId" element={<MediaItemView />}>
                <Route index element={<Navigate replace to="details" />} />
                <Route path="details" element={<MediaItemDetails />} />
                <Route path="thumbnails" element={<MediaItemThumbnails />} />
                <Route path="versions" element={<MediaItemVersions />} />
                <Route element={<PermissionFilter permission="canManage" />}>
                  <Route path="audit-logs" element={<MediaItemAuditLogs />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="playlists" element={<PermissionFilter permission="canAccessPlaylists" />}>
            <Route index element={<PlaylistList />} />
            <Route path=":playlistId">
              <Route index element={<Navigate replace to="design" />} />
              <Route path="design" element={<PlaylistDetailPage />} />
              <Route path="preview" element={<PlaylistPreviewPage />} />
              <Route path="settings" element={<PlaylistSettings />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<PlaylistAuditLogs />} />
              </Route>
            </Route>
          </Route>

          <Route path="settings" handle={{ breadcrumb: <SettingsBreadcrumb /> }}>
            <Route index element={<Navigate replace to="profile" />} />

            <Route path="profile" element={<UserProfile />} />

            <Route element={<PermissionFilter permission="canAccessFonts" />}>
              <Route
                path="fonts"
                element={<Fonts />}
                handle={{ breadcrumb: <Typography>Fonts</Typography> }}
              />
            </Route>

            <Route element={<PermissionFilter permission="canManage" />}>
              <Route path="network" element={<NetworksSettings />} />

              <Route path="roles" handle={{ breadcrumb: <RolesBreadcrumb /> }}>
                <Route index element={<RoleList />} />
                <Route path=":roleId" handle={{ breadcrumb: <RoleBreadcrumb /> }}>
                  <Route
                    path="audit-logs"
                    element={<RoleAuditLogs />}
                    handle={{
                      breadcrumb: (
                        <RoleListBreadcrumb segmentLink="audit-logs" segmentTitle="Audit Logs" />
                      ),
                    }}
                  />
                  <Route index element={<Navigate replace to="details" />} />
                  <Route
                    path="data-sources"
                    element={<RoleDataSourceList />}
                    handle={{
                      breadcrumb: (
                        <RoleListBreadcrumb
                          segmentLink="data-sources"
                          segmentTitle="Data Sources"
                        />
                      ),
                    }}
                  />
                  <Route
                    path="devices"
                    element={<RoleDeviceList />}
                    handle={{
                      breadcrumb: (
                        <RoleListBreadcrumb segmentLink="devices" segmentTitle="Devices" />
                      ),
                    }}
                  />
                  <Route path="details" element={<RoleSettings />} />
                  <Route
                    path="content-folders"
                    element={<RoleContentFolderList />}
                    handle={{
                      breadcrumb: (
                        <RoleListBreadcrumb
                          segmentLink="content-folders"
                          segmentTitle="Content Folders"
                        />
                      ),
                    }}
                  />
                  <Route
                    path="playlists"
                    element={<RolePlaylistList />}
                    handle={{
                      breadcrumb: (
                        <RoleListBreadcrumb segmentLink="playlists" segmentTitle="Playlists" />
                      ),
                    }}
                  />
                  <Route
                    path="shows"
                    element={<RoleShowList />}
                    handle={{
                      breadcrumb: <RoleListBreadcrumb segmentLink="shows" segmentTitle="Shows" />,
                    }}
                  />
                  <Route
                    path="users"
                    element={<RoleUserList />}
                    handle={{
                      breadcrumb: <RoleListBreadcrumb segmentLink="users" segmentTitle="Users" />,
                    }}
                  />
                </Route>
              </Route>

              <Route path="users" handle={{ breadcrumb: <UsersBreadcrumb /> }}>
                <Route index element={<UserList />} />
                <Route path=":userId" handle={{ breadcrumb: <UserBreadcrumb /> }}>
                  <Route index element={<Navigate replace to="details" />} />
                  <Route
                    path="details"
                    element={<UserSettings />}
                    handle={{
                      breadcrumb: (
                        <UserListBreadcrumb segmentLink="details" segmentTitle="Details" />
                      ),
                    }}
                  />
                  <Route
                    path="audit-logs"
                    element={<UserAuditLogs />}
                    handle={{
                      breadcrumb: (
                        <UserListBreadcrumb segmentLink="audit-logs" segmentTitle="Audit Logs" />
                      ),
                    }}
                  />
                </Route>
              </Route>

              <Route path="audit-logs" handle={{ breadcrumb: <AuditLogsBreadcrumb /> }}>
                <Route index element={<AuditLogList />} />
              </Route>

              <Route path="device-groups" handle={{ breadcrumb: <DeviceGroupsBreadcrumb /> }}>
                <Route index element={<SettingsDeviceGroupList />} />
                <Route path=":deviceGroupId" handle={{ breadcrumb: <DeviceGroupBreadcrumb /> }}>
                  <Route index element={<Navigate replace to="details" />} />
                  <Route path="details" element={<DeviceGroupSettings />} />
                  <Route
                    path="devices"
                    element={<DeviceGroupDevices />}
                    handle={{
                      breadcrumb: (
                        <DeviceGroupListBreadcrumb segmentLink="devices" segmentTitle="Devices" />
                      ),
                    }}
                  />
                  <Route
                    path="audit-logs"
                    element={<DeviceGroupAuditLogs />}
                    handle={{
                      breadcrumb: (
                        <DeviceGroupListBreadcrumb
                          segmentLink="audit-logs"
                          segmentTitle="Audit Logs"
                        />
                      ),
                    }}
                  />
                </Route>
              </Route>

              <Route path="event-types" handle={{ breadcrumb: <EventTypesBreadcrumb /> }}>
                <Route index element={<EventTypeList />} />
                <Route path="new" element={<NewEventType />} />
                <Route path=":eventTypeId" handle={{ breadcrumb: <EventTypeBreadcrumb /> }}>
                  <Route index element={<Navigate replace to="details" />} />
                  <Route path="details" element={<EventTypeSettings />} />
                  <Route
                    path="events"
                    element={<EventTypeEvents />}
                    handle={{
                      breadcrumb: (
                        <EventTypeListBreadcrumb segmentLink="events" segmentTitle="Events" />
                      ),
                    }}
                  />
                  <Route
                    path="shows"
                    element={<EventTypeShows />}
                    handle={{
                      breadcrumb: (
                        <EventTypeListBreadcrumb segmentLink="shows" segmentTitle="Shows" />
                      ),
                    }}
                  />
                  <Route
                    path="audit-logs"
                    element={<EventTypeAuditLogs />}
                    handle={{
                      breadcrumb: (
                        <EventTypeListBreadcrumb
                          segmentLink="audit-logs"
                          segmentTitle="Audit Logs"
                        />
                      ),
                    }}
                  />
                </Route>
              </Route>

              <Route path="tv-brands" handle={{ breadcrumb: <TVBrandsBreadcrumb /> }}>
                <Route index element={<TVBrandList />} />
                <Route path="new" element={<NewTVBrand />} />
                <Route path=":tvBrandId" handle={{ breadcrumb: <TVBrandBreadcrumb /> }}>
                  <Route index element={<Navigate replace to="details" />} />
                  <Route path="details" element={<TVBrandSettings />} />
                  <Route
                    path="audit-logs"
                    element={<TVBrandAuditLogs />}
                    handle={{
                      breadcrumb: (
                        <TVBrandListBreadcrumb segmentLink="audit-logs" segmentTitle="Audit Logs" />
                      ),
                    }}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path="reporting" element={<PermissionFilter permission="canAccessReporting" />}>
            <Route index element={<Reporting />} />
          </Route>

          <Route path="schedules" element={<PermissionFilter permission="canAccessScheduler" />}>
            <Route index element={<ScheduleList />} />
            <Route path=":scheduledShowId">
              <Route path="details" element={<ScheduleShowDetails />} />
            </Route>
          </Route>

          <Route path="shows" element={<PermissionFilter permission="canAccessShows" />}>
            <Route index element={<ShowList />} />
            <Route path=":showId">
              <Route index element={<Navigate replace to="design" />} />
              <Route path="advanced" element={<ShowDetailAdvancedPage />} />
              <Route path="design" element={<ShowDetailPage />} />
              <Route path="preview" element={<ShowPreviewPage />} />
              <Route path="settings" element={<ShowSettings />} />
              <Route element={<PermissionFilter permission="canManage" />}>
                <Route path="audit-logs" element={<ShowAuditLogs />} />
              </Route>
            </Route>
          </Route>

          <Route element={<PermissionFilter permission="canAccessStudio" />}>
            <Route path="studio">
              <Route index element={<StudioList />} />
              <Route path=":designId" element={<StudioLayout />}>
                <Route index element={<StudioEdit />} />
              </Route>
            </Route>
          </Route>

          <Route path="templates" element={<PermissionFilter permission="canAccessTemplates" />}>
            <Route index element={<TemplateList />} />
            <Route path=":templateId" element={<TemplateLayout />}>
              <Route path="configuration" element={<TemplateConfiguration />} />
              <Route path="settings" element={<TemplateSettings />} />
              <Route path="versions" element={<TemplateVersions />} />
            </Route>
            <Route path=":templateId/create" element={<CreateTemplate />} />
          </Route>

          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate replace to="./not-found" />} />
        </Route>
      </Route>

      <Route path="not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Route>,
  ),
);
