import {
  AccountCircle,
  AdminPanelSettings,
  AppRegistration,
  Assessment,
  AutoFixHigh,
  CalendarMonth,
  CloudSync,
  CropOriginal,
  Dashboard,
  DeviceHub,
  Dvr,
  Event,
  ExpandLess,
  ExpandMore,
  FontDownload,
  Group,
  Hub,
  Menu,
  MenuOpen,
  MovieCreation,
  OndemandVideo,
  ReceiptLong,
  Router,
  Schedule,
  Settings,
  Stadium,
  Tv,
  VerifiedUser,
  ViewComfy,
} from '@mui/icons-material';
import { Box, Collapse, IconButton, List, styled, useTheme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import type { CSSObject, Theme } from '@mui/material/styles';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { Link } from '~/components/link';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import logo from '~/images/fc-logo-alt.svg';
import { useResponsive } from '~/lib/responsive';
import { useSideNav } from '../context';
import { NavDivider, NavItemLink } from './NavItems';
import { NetworkButton } from './NetworkButton';
import { UserButton } from './UserButton';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    zIndex: '1200',
    marginTop: '55px;',
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.down('sm')]: {
    width: 0, // hide entirely
    position: 'fixed',
    zIndex: '1200',
    marginTop: '55px;',
  },
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const SideNav = () => {
  const { currentNetwork, currentUser } = useAppContext();

  const { isSmDown } = useResponsive();

  const theme = useTheme();

  const { open, toggleDrawer } = useSideNav();

  const location = useLocation();

  const link = useLink();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [schedulesOpen, setSchedulesOpen] = useState(false);

  const handleClickAway = () => {
    isSmDown && toggleDrawer();
  };

  const handleSettingsToggle = () => {
    setSettingsOpen(!settingsOpen);
  };

  const handleSchedulesToggle = () => {
    setSchedulesOpen(!schedulesOpen);
  };

  const [deviceFilters] = useLocalStorage('devices:filters', '');

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          bgcolor: 'primary.main',
        },
      }}
    >
      {!isSmDown && (
        <DrawerHeader
          sx={{
            justifyContent: 'flex-end',
          }}
        >
          {open && (
            <Link sx={{ flexBasis: '75%' }} to={link('/dashboard')}>
              <img
                src={logo}
                style={{
                  marginTop: theme.spacing(0.5),
                }}
              />
            </Link>
          )}
          <IconButton
            sx={{
              color: 'common.white',
            }}
            onClick={toggleDrawer}
          >
            {open ? <Menu /> : <MenuOpen />}
          </IconButton>
        </DrawerHeader>
      )}
      <Box>
        <List sx={{ height: 'calc(100vh - 160px)', overflowX: 'hidden', overflowY: 'auto' }}>
          <NetworkButton />

          <NavItemLink
            icon={<Dashboard />}
            text="Dashboard"
            to={link('/dashboard')}
            selected={location.pathname.includes('dashboard')}
            onClick={handleClickAway}
          />

          {currentNetwork.canAccessDevices.value && (
            <NavItemLink
              icon={<Router />}
              text="Devices"
              to={link(deviceFilters ? `/devices?${deviceFilters}` : '/devices')}
              selected={/^\/networks\/\d+\/devices/.test(location.pathname)}
              onClick={handleClickAway}
            />
          )}

          <NavDivider variant="middle" />

          {currentNetwork.canAccessContent.value && (
            <NavItemLink
              data-testid="content-nav-item"
              icon={<CropOriginal />}
              text="Content"
              to={link('/content')}
              selected={location.pathname.includes('content/')}
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canAccessDataSources.value && (
            <NavItemLink
              icon={<CloudSync />}
              text="Data Sources"
              to={link('/data-sources')}
              selected={
                location.pathname.includes('data-sources') &&
                !location.pathname.includes('settings')
              }
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canAccessPlaylists.value && (
            <NavItemLink
              icon={<OndemandVideo />}
              open={open}
              text="Playlists"
              to={link('/playlists')}
              selected={/^\/networks\/\d+\/playlists/.test(location.pathname)}
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canAccessShows.value && (
            <NavItemLink
              icon={<MovieCreation />}
              text="Shows"
              to={link('/shows')}
              selected={/^\/networks\/\d+\/shows/.test(location.pathname)}
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canAccessStudio.value && (
            <NavItemLink
              icon={<AutoFixHigh />}
              text="Studio"
              to={link('/studio')}
              selected={location.pathname.includes('studio')}
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canAccessScheduler.value && (
            <>
              <NavItemLink
                icon={<Event />}
                text="Schedules"
                onClick={handleSchedulesToggle}
                endIcon={schedulesOpen ? <ExpandLess /> : <ExpandMore />}
              />

              <Collapse in={schedulesOpen} timeout="auto">
                <List component="div">
                  <NavItemLink
                    icon={<CalendarMonth />}
                    text="Events"
                    to={link('/events')}
                    selected={/^\/networks\/\d+\/events/.test(location.pathname)}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<Schedule />}
                    text="Manual"
                    to={link('/schedules')}
                    selected={/^\/networks\/\d+\/schedules/.test(location.pathname)}
                    sub={true}
                    onClick={handleClickAway}
                  />
                </List>
              </Collapse>
            </>
          )}

          {currentNetwork.canAccessReporting.value && (
            <NavItemLink
              icon={<Assessment />}
              text="Reporting"
              to={link('/reporting')}
              selected={location.pathname.includes('reporting')}
              onClick={handleClickAway}
            />
          )}

          <NavDivider variant="middle" />

          {currentNetwork.canManage.value && (
            <NavItemLink
              icon={<Dvr />}
              text="Channel Guides"
              to={link('/channels')}
              selected={location.pathname.includes('channel')}
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canManage.value && (
            <NavItemLink
              icon={<ViewComfy />}
              text="Layouts"
              to={link('/layouts')}
              selected={location.pathname.includes('layouts')}
              onClick={handleClickAway}
            />
          )}

          {currentNetwork.canAccessTemplates.value && (
            <NavItemLink
              icon={<AppRegistration />}
              text="Templates"
              to={link('/templates')}
              selected={location.pathname.includes('templates')}
              onClick={handleClickAway}
            />
          )}

          <NavDivider variant="middle" />

          <NavItemLink
            icon={<Settings />}
            text="Settings"
            onClick={handleSettingsToggle}
            endIcon={settingsOpen ? <ExpandLess /> : <ExpandMore />}
          />

          <Collapse in={settingsOpen} timeout="auto">
            <List component="div">
              <NavItemLink
                icon={<AccountCircle />}
                text="My Profile"
                to={link('/settings/profile')}
                selected={location.pathname.includes('settings/profile')}
                sub={true}
                onClick={handleClickAway}
              />
              {currentNetwork.canAccessFonts.value && (
                <NavItemLink
                  icon={<FontDownload />}
                  text="Fonts"
                  to={link('/settings/fonts')}
                  selected={location.pathname.includes('settings/fonts')}
                  sub={true}
                  onClick={handleClickAway}
                />
              )}
              {currentNetwork.canManage.value && (
                <>
                  <NavItemLink
                    icon={<Hub />}
                    text="Network"
                    to={link('/settings/network')}
                    selected={location.pathname.includes('settings/network')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<DeviceHub />}
                    text="Device Groups"
                    to={link('/settings/device-groups')}
                    selected={location.pathname.includes('settings/device-groups')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<Stadium />}
                    text="Event Types"
                    to={link('/settings/event-types')}
                    selected={location.pathname.includes('settings/event-types')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<VerifiedUser />}
                    text="Roles"
                    to={link('/settings/roles')}
                    selected={location.pathname.includes('settings/roles')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<Group />}
                    text="Users"
                    to={link('/settings/users')}
                    selected={location.pathname.includes('settings/users')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<Tv />}
                    text="TV Brands"
                    to={link('/settings/tv-brands')}
                    selected={location.pathname.includes('settings/tv-brands')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                  <NavItemLink
                    icon={<ReceiptLong />}
                    text="Audit Logs"
                    to={link('/settings/audit-logs')}
                    selected={location.pathname.includes('settings/audit-logs')}
                    sub={true}
                    onClick={handleClickAway}
                  />
                </>
              )}
            </List>
          </Collapse>
        </List>
      </Box>
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          bottom: '0',
        }}
      >
        <List sx={{ overflowX: 'hidden' }}>
          {currentUser.admin && (
            <NavItemLink icon={<AdminPanelSettings />} text="Admin Site" href="/admin" />
          )}
          <UserButton />
        </List>
      </Box>
    </Drawer>
  );
};
