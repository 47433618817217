import { Edit, MoreHoriz } from '@mui/icons-material';
import { Chip, useMediaQuery, useTheme } from '@mui/material';
import type { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { ActionButtonLink } from '~/components/button';
import type { ChannelGuideList__ChannelGuide as ChannelGuide } from '../../queries/list.generated';
import { Menu } from '../components/menu';

export const useColumns = (): GridColDef[] => {
  const theme = useTheme();
  const isSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  return useMemo(
    () => [
      {
        field: 'name',
        flex: 1,
        headerName: 'Name',
      },
      {
        field: 'channels',
        headerName: 'Channels',
        sortable: false,
        renderCell: ({
          value: channels,
        }: GridRenderCellParams<ChannelGuide, ChannelGuide['channels']>) => {
          return <Chip color="primary" label={channels?.length || 0} />;
        },
        minWidth: 50,
      },
      {
        field: 'shows',
        headerName: 'Shows',
        sortable: false,
        renderCell: ({ row }) => {
          return <Chip color="primary" label={row.showCount} />;
        },
        minWidth: 50,
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: isSmallAndUp ? 150 : 0,
        getActions: ({ row: channelGuide }: GridRowParams<ChannelGuide>) => {
          return [
            <ActionButtonLink
              key={`edit-${channelGuide.id}`}
              title="Edit"
              Icon={Edit}
              to={`${channelGuide.id}`}
            />,
            <Menu
              key={`more-action-${channelGuide.id}`}
              channelGuide={channelGuide}
              Icon={MoreHoriz}
              buttonType="action"
            />,
          ];
        },
      },
    ],
    [isSmallAndUp],
  );
};
