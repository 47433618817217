import { Tabs as MuiTabs, type TabsProps as MuiTabProps } from '@mui/material';
import { parsePath, resolvePath, useLocation, useResolvedPath, type To } from 'react-router-dom';
import type { TabLinkProps } from './link';
import { TabLink } from './link';
export interface TabEntry {
  label: string;
  to: To;
}
export interface RouterTabsProps extends Omit<MuiTabProps, 'children' | 'value'> {
  /** A list of tabs to render */
  tabs: ReadonlyArray<TabEntry>;
  /** Props to be applied to individual tabs */
  TabProps?: Omit<TabLinkProps, 'to' | 'value'>;
}

/**
 * Tabs tied to the router state
 *
 * This is the One True RouterTabs, to be used above all else.
 *
 * **NOTE:** You can pass any `TabsProps` from the MUI API here. You can also pass `TabProps`
 * which will be applied to individual tabs.
 */
export const RouterTabs = ({ tabs, TabProps, ...props }: RouterTabsProps) => {
  const location = useLocation();
  const base = useResolvedPath('', { relative: 'route' });

  const tabProps = tabs.map(({ label, to }, key) => {
    const path = typeof to === 'string' ? parsePath(to) : to;
    const value = resolvePath(path, base.pathname).pathname;

    return { key, label, to, value };
  });

  if (!tabProps.some(({ value }) => value === location.pathname)) return null;

  return (
    <MuiTabs value={location.pathname} variant="fullWidth" {...props}>
      {tabProps.map(({ key, ...props }) => (
        <TabLink key={key} {...props} {...TabProps} />
      ))}
    </MuiTabs>
  );
};

export interface ScrollableRouterTabsProps extends RouterTabsProps {
  current: TabEntry['label'];
}

export const ScrollableRouterTabs = ({ current, tabs, ...props }: ScrollableRouterTabsProps) => (
  <nav>
    <RouterTabs
      allowScrollButtonsMobile
      scrollButtons
      sx={{ padding: 0, paddingTop: 1 }}
      tabs={tabs.map((tab) => ({ ...tab, to: current === tab.label ? '' : tab.to }))}
      variant="scrollable"
      {...props}
    />
  </nav>
);
