import { Hub } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Avatar, styled } from '@mui/material';

export const CustomAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

export interface NetworkAvatarProps {
  icon: string | null | undefined;
  sx?: SxProps<Theme>;
}

export const NetworkAvatar = ({ icon, sx }: NetworkAvatarProps) => {
  return icon ? (
    <CustomAvatar src={icon} sx={sx} />
  ) : (
    <CustomAvatar sx={{ color: 'secondary.main', ...sx }}>
      <Hub />
    </CustomAvatar>
  );
};
