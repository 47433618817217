import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPane } from '~/components/loading-pane';
import { PageContainer } from '~/components/page-layout';
import { ScrollableRouterTabs } from '~/components/RouterTabs';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { useMoreActions } from '~/hooks/table';
import type { ChannelGuideSettings__ChannelGuide as ChannelGuide } from '../queries/settings.generated';
import { ChannelGuideSettingsDocument } from '../queries/settings.generated';
import { Actions, Channels, Details, Menu, Toolbar } from './components';
import { tabs } from './lib/tabs';

export const ChannelGuideDetailPage = () => {
  const { currentNetwork } = useAppContext();
  const params = useParams<{ channelGuideId: string }>();
  const channelGuideId = parseInt(params.channelGuideId ?? '');

  const { data, loading } = useQuery(ChannelGuideSettingsDocument, {
    variables: { channelGuideId, networkId: currentNetwork.id },
  });

  const link = useLink();

  const channelGuide = useMemo(() => data?.network?.channelGuide, [data]);

  const [moreMenuProps, moreActionProps, moreTableActions] = useMoreActions<ChannelGuide>();

  const onShowMenu = channelGuide ? moreTableActions(channelGuide).onClick : undefined;

  if (!loading && !data?.network?.channelGuide) return <Navigate to={link('/not-found')} replace />;

  if (!channelGuide) return null;

  return (
    <LoadingPane in={loading && !data} size={80} thickness={4}>
      <Toolbar actions={<Actions channelGuide={channelGuide} onShowMenu={onShowMenu} />} />
      <ScrollableRouterTabs current="Channels" tabs={tabs} />
      <Menu
        channelGuide={channelGuide}
        moreMenuProps={moreMenuProps}
        moreActionProps={moreActionProps}
      />
      <PageContainer>
        <Details channelGuide={channelGuide} />
        <Channels channelGuide={channelGuide} channels={data?.network?.channels ?? []} />
      </PageContainer>
    </LoadingPane>
  );
};
