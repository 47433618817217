import { Box, Button, useTheme, type DialogProps } from '@mui/material';
import type { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useAddChannelsToChannelGuide } from '~/api/channel-guides';
import { Count } from '~/components/count';
import { SeparatedDataGrid } from '~/components/data-grid';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import { searchRegex } from '~/lib/search';
import type {
  ChannelGuideSettings__Channel as Channel,
  ChannelGuideSettings__ChannelGuide as ChannelGuide,
} from '../queries/settings.generated';

export interface AddChannelsDialogProps extends Omit<DialogProps, 'children'> {
  channelGuide: ChannelGuide;
  channels: readonly Channel[];
  close: () => void;
  open: boolean;
}

export const AddChannelsDialog = ({
  channelGuide,
  channels,
  close,
  open,
}: AddChannelsDialogProps) => {
  const theme = useTheme();

  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [addChannels] = useAddChannelsToChannelGuide({
    variables: {
      input: { channelGuideId: channelGuide.id, channelIds: selectedIds.map((id) => +id) },
    },
  });

  const channelIds = new Set(channelGuide.channels.map((x) => x.id));

  const searchReg = searchRegex(search);

  const rows = channels.filter((x) => !channelIds.has(x.id) && searchReg.test(x.name));

  const clearState = () => {
    setSelectedIds([]);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={close}
      TransitionProps={{ onExited: clearState }}
    >
      <DialogTitle onClose={close}>Add Channels</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Channels to add to the <b>{channelGuide.name}</b> Channel Guide.
        </DialogContentTitle>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Channels" search={search} />
          <Count selectedCount={selectedIds.length} totalCount={rows.length} thing="channel" />
        </Box>

        <Box>
          <SeparatedDataGrid
            checkboxSelection
            columns={[
              {
                field: 'thumbnailFile',
                headerName: 'Thumbnail',
                minWidth: 100,
                renderCell: ({ row: channel, value: thumbnailFile }) => {
                  return thumbnailFile ? (
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        backgroundColor: channel.backgroundColor,
                        height: '75%',
                      }}
                    >
                      <img
                        crossOrigin="anonymous"
                        src={thumbnailFile.uri}
                        style={{ margin: '0.5rem', height: '100%' }}
                      />
                    </Box>
                  ) : null;
                },
              } satisfies GridColDef<Channel, Channel['thumbnailFile']>,
              {
                field: 'number',
                resizable: false,
                sortable: true,
                minWidth: 100,
              },
              {
                field: 'name',
                flex: 1,
                resizable: true,
                sortable: true,
              },
              {
                field: 'source',
                minWidth: 125,
                resizable: false,
                sortable: true,
              },
            ]}
            onRowSelectionModelChange={setSelectedIds}
            rows={rows}
            rowSelectionModel={selectedIds}
            slots={{
              baseCheckbox: ListCheckbox,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No Channels available
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={!selectedIds.length}
          onClick={() => {
            close();
            return addChannels();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
