import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { type useFormik } from 'formik';
import { MuiColorInput } from 'mui-color-input';
import { useMemo, type ComponentProps } from 'react';
import { useDropzone } from 'react-dropzone';
import { DialogForm } from '~/components/dialogs/components';
import { ChannelEncryptionKind } from '~/generated/graphql';
import { toDataUri } from '~/lib/file';
import { formatEncryption } from '~/pages/channels/lib/formatters';

// Order these how you want
const encyptionOptions = [
  ChannelEncryptionKind.None,
  ChannelEncryptionKind.Aes_128,
  ChannelEncryptionKind.Aes_256,
];

export interface ChannelFormValues {
  backgroundColor: string;
  encryption: ChannelEncryptionKind;
  name: string;
  number: string;
  source?: string;
  thumbnailUri: string;
  url: string;
  urls: readonly string[];
}

export interface ChannelFormProps<T extends ChannelFormValues>
  extends Omit<ComponentProps<'form'>, 'onSubmit'> {
  formik: ReturnType<typeof useFormik<T>>;
  thumbnailFileUri?: string | undefined;
}

export const ChannelForm = <T extends ChannelFormValues>({
  formik,
  thumbnailFileUri,
  ...props
}: ChannelFormProps<T>) => {
  const { getInputProps, open: selectFile } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: async (accepted, _rejected, _event) => {
      if (accepted.length !== 1) return;
      const thumbnailUri = await toDataUri(accepted[0]);
      await formik.setFieldValue('thumbnailUri', thumbnailUri, true);
    },
  });

  const imgSrc = formik.values.thumbnailUri.trim() || thumbnailFileUri;

  const selectedIndex = useMemo(() => {
    if (!formik.values.url) return -1;
    return formik.values.urls.findIndex((url) => url === formik.values.url);
  }, [formik.values.urls, formik.values.url]);

  return (
    <DialogForm onSubmit={formik.handleSubmit} {...props}>
      <label>
        <span className={`label required`}>Name</span>
        <TextField
          error={formik.touched.name && !!formik.errors.name}
          fullWidth
          helperText={(formik.touched.name && formik.errors.name) || ' '}
          name="name"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.name}
          focused
        />
      </label>

      <label>
        <span className="label required">URL(s)</span>
        <RadioGroup
          sx={{
            marginBottom: '16px',
            width: '100%',
            '.MuiFormControlLabel-root': {
              marginRight: 0,
            },
          }}
          value={formik.values.url}
        >
          {[0, 1, 2, 3, 4].map((index) => (
            <FormControlLabel
              key={`channel-url-${index}`}
              control={
                <Radio
                  checked={selectedIndex === index}
                  disabled={!formik.values.urls[index]}
                  onChange={async (_e) => {
                    await formik.setFieldValue('url', formik.values.urls[index], true);
                  }}
                />
              }
              label={
                <TextField
                  value={formik.values.urls[index]}
                  onChange={async (e) => {
                    const urls = [...formik.values.urls];
                    urls[index] = e.target.value;
                    await formik.setFieldValue('urls', urls, true);
                    if (selectedIndex === index)
                      await formik.setFieldValue('url', e.target.value, true);
                  }}
                  placeholder="Enter Channel URL"
                  size="small"
                  fullWidth
                />
              }
              sx={{
                alignItems: 'center !important',
                '.MuiFormControlLabel-label': {
                  width: '100%',
                },
              }}
            />
          ))}
          <FormHelperText>
            {formik.errors.urls ? (
              <span style={{ color: '#f55459' }}>{formik.errors.urls}</span>
            ) : (
              `Enter up to 5 Channel URLs, select the primary.`
            )}
          </FormHelperText>
        </RadioGroup>
      </label>

      <label>
        <span className="label required">Channel #</span>
        <TextField
          error={formik.touched.number && !!formik.errors.number}
          helperText={(formik.touched.number && formik.errors.number) || ' '}
          name="number"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.number}
        />
      </label>

      <label>
        <span className="label required">Background Color</span>
        <MuiColorInput
          error={formik.touched.backgroundColor && !!formik.errors.backgroundColor}
          format="hex"
          helperText={(formik.touched.backgroundColor && formik.errors.backgroundColor) || ' '}
          name="backgroundColor"
          onBlur={formik.handleBlur}
          onChange={(color) => formik.setFieldValue('backgroundColor', color, true)}
          size="small"
          value={formik.values.backgroundColor}
          isAlphaHidden={true}
        />
      </label>

      <label>
        <span className="label required">Thumbnail</span>
        {imgSrc && (
          <Box
            alignSelf="flex-start"
            display="flex"
            justifyContent="center"
            maxHeight="8rem"
            maxWidth="8rem"
            mr={2}
          >
            <img src={imgSrc} />
          </Box>
        )}
        <input {...getInputProps()} />
        <Box display="flex" flexDirection="column" flexGrow={1} height="8rem">
          <Box>
            <Button onClick={selectFile} variant="outlined" size="small">
              Upload Thumbnail
            </Button>
          </Box>
          <Box mt={1}>
            Or, enter an image URL:
            <TextField
              fullWidth
              name="thumbnailUri"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              size="small"
            />
          </Box>
        </Box>
      </label>

      <label>
        <span className="label required">Encryption</span>
        <TextField
          error={formik.touched.encryption && !!formik.errors.encryption}
          fullWidth
          helperText={(formik.touched.encryption && formik.errors.encryption) || ' '}
          name="encryption"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          select
          size="small"
          value={formik.values.encryption}
        >
          {encyptionOptions.map((kind) => (
            <MenuItem key={kind} value={kind}>
              {formatEncryption(kind)}
            </MenuItem>
          ))}
        </TextField>
      </label>

      <label>
        <span className="label">Source</span>
        <TextField
          error={formik.touched.source && !!formik.errors.source}
          helperText={(formik.touched.source && formik.errors.source) || ' '}
          name="source"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          size="small"
          value={formik.values.source}
        />
      </label>
    </DialogForm>
  );
};
