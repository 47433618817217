import { Menu, MenuOpen } from '@mui/icons-material';
import { Box, Icon, IconButton, styled, Typography, useTheme } from '@mui/material';
import { withStyles } from '@mui/styles';
import type { ReactNode } from 'react';
import { useSideNav } from '~/layouts/context';
import { formatTimeAgo } from '~/lib/datetime';
import { useResponsive } from '~/lib/responsive';
import { InlineEditTextField } from './forms/inline-edit-text-field';

export const InlineEditTitle = withStyles({
  root: {
    width: '70%',
  },
  label: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',
  },
  input: {
    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',
  },
})(InlineEditTextField);

const ToolbarContainer = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  alignItems: 'center',
  background: '#ffffff',
  borderBottom: '1px solid rgb(63, 63, 63, .15)',
  display: 'flex',
  position: 'sticky',
  top: '64px',
  width: '100%',
  zIndex: '999',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1),
  },
}));

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'center',
  alignItems: 'center',
  order: 1,
  flexGrow: 1,
  flexShrink: 0,
  '& > h1': {
    marginLeft: theme.spacing(1),
  },
}));

const TitleIcon = styled(Box)({
  fontSize: '35px',
  '& > span': {
    fontSize: '35px',
  },
  '& svg': {
    fontSize: '35px',
  },
});

const ChildrenContainer = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  order: 2,
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(0),
  },
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  background: '#ffffff',
  order: 3,
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    justifyContent: 'center',
    borderTop: '1px solid #cccccc',
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(0),
    zIndex: '1000',
    '& .MuiButton-root': {
      maxWidth: '100%',
      width: '100%',
    },
  },
}));
export interface ToolbarProps {
  actions?: ReactNode;
  children?: ReactNode;
  createdAt?: string;
  titleIcon?: ReactNode;
  titleText?: ReactNode;
  titleEditor?: ReactNode;
  titleLabel?: string;
  titleTextCustom?: string;
  updatedAt?: string;
  breadcrumbsLabel?: ReactNode;
}

export const Toolbar = ({
  actions,
  children,
  createdAt,
  titleIcon,
  titleText,
  titleEditor,
  titleLabel,
  breadcrumbsLabel,
  titleTextCustom,
  updatedAt,
}: ToolbarProps) => {
  const theme = useTheme();
  const { isSmDown } = useResponsive();
  const { open, toggleDrawer } = useSideNav();

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <ToolbarContainer>
        <TitleContainer>
          {isSmDown && (
            <Box
              sx={{
                borderRight: '1px solid rgb(63, 63, 63, .15)',
                marginRight: theme.spacing(1),
              }}
            >
              <IconButton
                sx={{
                  color: 'primary.main',
                  '& svg': {
                    fontSize: '30px',
                  },
                }}
                onClick={toggleDrawer}
              >
                {open ? <Menu /> : <MenuOpen />}
              </IconButton>
            </Box>
          )}

          {titleIcon && (
            <TitleIcon>
              <Icon color="primary">{titleIcon}</Icon>
            </TitleIcon>
          )}

          <Box
            sx={{
              width: '100%',
              marginLeft: '10px',
            }}
          >
            {titleEditor && <div>{titleEditor}</div>}

            {titleText && (
              <Typography variant="h3" noWrap>
                {titleText}
              </Typography>
            )}

            {titleTextCustom && (
              <Typography
                variant="h3"
                noWrap
                sx={{
                  fontSize: '20px',
                }}
              >
                {titleTextCustom}
              </Typography>
            )}

            {titleLabel && <p>{titleLabel}</p>}
            {breadcrumbsLabel && <div>{breadcrumbsLabel}</div>}

            {(updatedAt || createdAt) && (
              <Typography
                sx={{
                  color: '#808080',
                  fontSize: '.8rem',
                }}
              >
                {updatedAt ? 'Updated' : 'Created'} {formatTimeAgo(updatedAt ?? createdAt!)}
              </Typography>
            )}
          </Box>
        </TitleContainer>

        <ChildrenContainer>{children}</ChildrenContainer>

        {actions !== undefined && <ActionsContainer>{actions}</ActionsContainer>}
      </ToolbarContainer>
    </Box>
  );
};
