import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuditLogList } from '~/components/AuditLogs';
import { PageContainer } from '~/components/page-layout';
import { ScrollableRouterTabs } from '~/components/RouterTabs';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { useMoreActions } from '~/hooks/table';
import type { ChannelGuideSettings__ChannelGuide as ChannelGuide } from '../queries/settings.generated';
import { ChannelGuideSettingsDocument } from '../queries/settings.generated';
import { Actions, Menu, Toolbar } from './components';
import { tabs } from './lib/tabs';

export const ChannelGuideAuditLogs = () => {
  const params = useParams<{ channelGuideId: string }>();
  const channelGuideId = parseInt(params.channelGuideId ?? '');

  const { currentNetwork } = useAppContext();
  const link = useLink();

  const { data, loading } = useQuery(ChannelGuideSettingsDocument, {
    variables: { networkId: currentNetwork.id, channelGuideId },
  });

  const channelGuide = useMemo(() => data?.network?.channelGuide, [data]);

  const [moreMenuProps, moreActionProps, moreTableActions] = useMoreActions<ChannelGuide>();

  const onShowMenu = channelGuide ? moreTableActions(channelGuide).onClick : undefined;

  if (!loading && !data?.network?.channelGuide) return <Navigate to={link('/not-found')} replace />;

  if (!channelGuide) return null;

  return (
    <>
      <Toolbar actions={<Actions channelGuide={channelGuide} onShowMenu={onShowMenu} />} />
      <ScrollableRouterTabs current="Audit Logs" tabs={tabs} />
      <Menu
        channelGuide={channelGuide}
        moreMenuProps={moreMenuProps}
        moreActionProps={moreActionProps}
      />
      <PageContainer>
        <AuditLogList recordTypes={['ChannelGuide']} channelGuideId={channelGuideId} />
      </PageContainer>
    </>
  );
};
