import { useMediaQuery, useTheme } from '@mui/material';
import type { GridColumnVisibilityModel, GridRowParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { SeparatedDataGrid } from '~/components/data-grid';
import { EmptyState } from '~/components/empty-state';
import CreateChannelGuideIllustration from '~/images/illustrations/create-channel-guide.svg';
import { EditChannelDialog } from '../../components/EditDialog';
import { type ChannelList__Channel as Channel } from '../../queries/list.generated';
import { useColumns } from '../lib/columns';

const NoRowsOverlay = () => (
  <EmptyState
    illustration={CreateChannelGuideIllustration}
    header="Create a Channel"
    description="Channels represent IPTV content."
  />
);

export interface TableProps {
  channels: readonly Channel[];
  loading: boolean;
  onEditChannel: (channel: Channel) => void;
}

export const Table = ({ channels, loading, onEditChannel }: TableProps) => {
  const columns = useColumns({ onEditChannel });
  const [showColumns, setShowColumns] = useState<GridColumnVisibilityModel>({});
  const [currentChannel, setCurrentChannel] = useState<Channel>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setShowColumns((x) => ({
      ...x,
      encryption: !isSmallAndDown,
      source: !isSmallAndDown,
      thumbnailFile: !isSmallAndDown,
      url: !isSmallAndDown,
    }));
  }, [isSmallAndDown]);

  return (
    <>
      <SeparatedDataGrid
        checkboxSelection={false}
        columns={columns}
        columnVisibilityModel={showColumns}
        disableRowSelectionOnClick
        loading={loading}
        onColumnVisibilityModelChange={setShowColumns}
        onRowClick={({ row: channel }: GridRowParams<Channel>) => {
          setCurrentChannel(channel);
          setEditDialogOpen(true);
        }}
        rows={channels}
        slots={{
          noRowsOverlay: NoRowsOverlay,
        }}
      />
      {currentChannel && (
        <EditChannelDialog
          channel={currentChannel}
          close={() => setEditDialogOpen(false)}
          open={editDialogOpen}
        />
      )}
    </>
  );
};
