import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu as MuiMenu, Tooltip } from '@mui/material';
import { type ElementType } from 'react';
import { useDeleteChannelGuide } from '~/api/channel-guides';
import { ActionButton } from '~/components/button';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { useMoreActions } from '~/hooks/table';
import { assert } from '~/lib/assert';
import type { ChannelGuideList__ChannelGuide as ChannelGuide } from '../../queries/list.generated';

export interface MenuProps {
  buttonType?: 'action' | 'icon';
  channelGuide: ChannelGuide;
  Icon?: ElementType;
}

export const Menu = ({ buttonType = 'icon', channelGuide, Icon = MoreVert }: MenuProps) => {
  const { currentNetwork } = useAppContext();

  const [moreMenuProps, moreActionProps, moreTableActionProps] = useMoreActions<ChannelGuide>();
  const channelGuideActionProps = moreTableActionProps(channelGuide);
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();
  const [deleteChannelGuide] = useDeleteChannelGuide();

  return (
    <>
      {buttonType === 'icon' ? (
        <Tooltip title="Actions">
          <IconButton aria-label="actions" onClick={channelGuideActionProps.onClick} size="small">
            <Icon />
          </IconButton>
        </Tooltip>
      ) : (
        <ActionButton {...channelGuideActionProps} />
      )}
      <MuiMenu {...moreMenuProps}>
        <Tooltip title={channelGuide.canDestroy.reasons?.fullMessages[0]}>
          <div>
            <MoreDeleteAction
              disabled={!channelGuide.canDestroy.value}
              {...moreActionProps}
              onClick={async () => {
                assert(moreMenuProps.context !== undefined);
                if (!(await confirmDelete())) return;
                await deleteChannelGuide({
                  variables: {
                    input: {
                      channelGuideIds: [moreMenuProps.context.id],
                      networkId: currentNetwork.id,
                    },
                  },
                });
                moreMenuProps.onClose();
              }}
            />
          </div>
        </Tooltip>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Channel Guide will remove it from Devices where it is being used."
        title="Delete Channel Guide"
      />
    </>
  );
};
