import { Box, Button, type DialogProps } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAddDevicesToDeviceGroupFromSettings } from '~/api/device-groups';
import { Count } from '~/components/count';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DeviceIndicators } from '~/components/devices';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import { searchRegex } from '~/lib/search';
import type {
  NetworkDeviceGroupDevices__Device,
  NetworkDeviceGroupDevices__DeviceGroup,
} from '../../queries/devices.generated';

export interface AddDevicesDialogProps extends Omit<DialogProps, 'deviceGroup'> {
  devices: readonly NetworkDeviceGroupDevices__Device[];
  deviceGroup: NetworkDeviceGroupDevices__DeviceGroup;
}

export const AddDevicesDialog = ({ devices, deviceGroup, ...props }: AddDevicesDialogProps) => {
  const [searchParams] = useSearchParams();
  const listSearch = searchParams.get('search') || '';

  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [addToDeviceGroup, { called }] = useAddDevicesToDeviceGroupFromSettings({
    refetchQueries: ['NetworkDeviceGroupDevices'], // TODO: replace with mutation cache response
  });

  const searchReg = searchRegex(search);

  const rows = devices.filter(
    (item) => searchReg.test(String(item.id)) || searchReg.test(item.name),
  );

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Devices</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Devices to add to the <b>{deviceGroup.name}</b> Device Group.
        </DialogContentTitle>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Devices" search={search} />
          <Count
            selectedCount={selectedIds.length}
            totalCount={devices.length || 0}
            thing="device"
          />
        </Box>

        <Box>
          <SeparatedDataGrid
            autoHeight={false}
            checkboxSelection
            columns={[
              {
                field: 'indicators',
                headerName: '',
                sortable: false,
                resizable: false,
                width: 150,

                renderCell: ({ row }) => <DeviceIndicators device={row} />,
              },
              {
                field: 'id',
                width: 65,
                valueGetter: (_value, row) => row.id,
              },
              { field: 'name', flex: 1 },
            ]}
            onRowSelectionModelChange={setSelectedIds}
            rowSelectionModel={selectedIds}
            rows={rows}
            slots={{
              baseCheckbox: ListCheckbox,
              columnResizeIcon: () => null,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No devices exist
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || !selectedIds.length}
          onClick={() => {
            close();
            return addToDeviceGroup({
              variables: {
                deviceGroupId: deviceGroup.id,
                deviceIds: selectedIds.map((id) => +id),
                search: listSearch,
              },
            });
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
