import { createContext, useContext, type ReactNode } from 'react';
import { assert } from '~/lib/assert';
import type { DeviceFilters, DeviceFiltersState } from './';

export type DeviceFiltersContextType = DeviceFiltersState & {
  clearFilters: () => void;
  setFilter: (
    field: {
      [K in keyof DeviceFilters]: { field: K; items: DeviceFilters[K]['items']; open?: boolean };
    }[keyof DeviceFilters],
  ) => void;
  toggleFilter: (field: keyof DeviceFilters) => void;
  togglePanel: () => void;
};

export const DeviceFiltersContext = createContext<DeviceFiltersContextType | undefined>(undefined);

export const useDeviceFilters = () => {
  const context = useContext(DeviceFiltersContext);
  assert(context !== undefined, 'useDeviceFilters must be used with a DeviceFiltersProvider');
  return context;
};

export interface DeviceFiltersProviderProps extends DeviceFiltersContextType {
  children: ReactNode;
}

export const DeviceFiltersProvider = ({ children, ...rest }: DeviceFiltersProviderProps) => (
  <DeviceFiltersContext.Provider value={{ ...rest }}>{children}</DeviceFiltersContext.Provider>
);
