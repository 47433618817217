import { useQuery } from '@apollo/client';
import { Dvr } from '@mui/icons-material';
import type { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useUpdateChannelGuide } from '~/api/channel-guides';
import { InlineEditTitle, Toolbar as MuiToolbar } from '~/components/toolbar';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { ChannelGuideToolbarDocument } from '../../queries/toolbar.generated';

export interface ToolbarProps {
  actions?: ReactNode;
}

export const Toolbar = ({ actions }: ToolbarProps) => {
  const { currentNetwork } = useAppContext();

  const link = useLink();

  const params = useParams<{ channelGuideId: string }>();
  const channelGuideId = parseInt(params.channelGuideId ?? '');

  const { data, loading } = useQuery(ChannelGuideToolbarDocument, {
    variables: { networkId: currentNetwork.id, channelGuideId },
  });

  const [updateChannelGuide] = useUpdateChannelGuide();

  const channelGuide = data?.network?.channelGuide;

  if (loading || !channelGuide)
    return !loading ? <Navigate to={link('/not-found')} replace /> : null;

  return (
    <MuiToolbar
      actions={actions}
      titleIcon={<Dvr />}
      titleEditor={
        <InlineEditTitle
          inputId="edit-channel-guide-name"
          value={channelGuide.name}
          update={(name) =>
            updateChannelGuide({ variables: { input: { id: channelGuide.id, patch: { name } } } })
          }
          tooltip="Edit the name of this channel guide"
          validationSchema={object({ name: string().required().trim().label('Name') })}
        />
      }
    />
  );
};
