import { useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib/styles';
import { ListCheckbox } from '~/components/list-row';
import { useAppContext } from '~/contexts';
import { useTotalCount } from '~/hooks/pagination';
import {
  DataItemSelectGetDocument,
  type DataItemSelect__DataItemListed as DataItem,
  type DataItemSelect__DataSourceListed as DataSourceListed,
} from '../DataItemSelect.generated';
import { SpreadsheetyDataGrid, useDataSourceTable } from '../lib';
import { DataItemSelectToolbar } from './';
import { DataGridPagination } from '~/components/data-grid';

export interface DataItemSelectModalProps {
  dataItem: DataItem | null;
  dataSources: ReadonlyArray<DataSourceListed>;
  onChange: (dataItemId: number | null) => void;
  onClose: () => void;
  optionName: string;
}

export const DataItemSelectModal = ({
  dataItem,
  dataSources,
  onChange,
  onClose,
  optionName,
}: DataItemSelectModalProps) => {
  const { currentNetwork } = useAppContext();
  const [selectedItemId, setSelectedItemId] = useState<number | null>(dataItem?.id ?? null);
  const [selectedSourceId, setSelectedSourceId] = useState<number | null>(
    dataItem?.source.id ?? dataSources.at(0)?.id ?? null,
  );
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [getDataSource, selectedSourceQuery] = useLazyQuery(DataItemSelectGetDocument);

  useEffect(() => {
    if (!selectedSourceId) return;
    void getDataSource({
      variables: {
        dataSourceId: selectedSourceId,
        networkId: currentNetwork.id,
        orderFirst: dataItem?.id,
        page,
        perPage: 50,
        search,
      },
    });
  }, [currentNetwork.id, dataItem?.id, getDataSource, page, search, selectedSourceId]);

  const onPaginationModelChange = useCallback(
    ({ page }: GridPaginationModel) => {
      setPage(page + 1);
    },
    [setPage],
  );

  const dataSource = useMemo(
    () => selectedSourceQuery.data?.network?.dataSource,
    [selectedSourceQuery],
  );

  const { columns, rows } = useDataSourceTable(dataSource);
  const totalCount = useTotalCount(dataSource?.items.pageInfo.nodesCount);

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onClose} open>
      <DialogTitle onClose={onClose}>Select Row</DialogTitle>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <DialogContentTitle>
          Select one row from a Data Source to use for <strong>{optionName}</strong>.
        </DialogContentTitle>
        <DataItemSelectToolbar
          dataSources={dataSources}
          onSearch={(x) => setSearch(x)}
          onSourceSelect={(x) => setSelectedSourceId(() => x)}
          rowCount={totalCount}
          search={search}
          selectedSourceId={selectedSourceId}
        />
        <SpreadsheetyDataGrid
          checkboxSelection
          columns={columns}
          disableColumnMenu
          disableRowSelectionOnClick
          initialState={{
            pagination: {
              paginationModel: { pageSize: 50, page: 0 },
            },
          }}
          loading={selectedSourceQuery.loading}
          onPaginationModelChange={onPaginationModelChange}
          onRowSelectionModelChange={(ids) => {
            if (ids.length === 0 && rows.find((x) => x.id === selectedItemId)) {
              return setSelectedItemId(() => null);
            }
            const newIds = ids.filter((x) => x !== selectedItemId);
            const id = Number(newIds.at(0));
            if (id) setSelectedItemId(() => id);
          }}
          pagination
          paginationMode="server"
          paginationModel={{ page: page - 1, pageSize: 50 }}
          rowCount={totalCount}
          rowSelectionModel={selectedItemId ? [selectedItemId] : []}
          rows={rows}
          slots={{
            baseCheckbox: ListCheckbox,
            pagination: DataGridPagination,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'skeleton',
            },
            pagination: {
              rowsPerPageOptions: [],
            },
          }}
          sx={{
            '--DataGrid-overlayHeight': '300px',
            height: '60vh',
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={(!dataItem && !selectedItemId) || dataItem?.id === selectedItemId}
          onClick={() => {
            onChange(selectedItemId);
            onClose();
          }}
          type="submit"
          variant="contained"
        >
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};
