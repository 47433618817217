import { styled } from '@mui/material';

export const ChannelActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing(2),
}));

export const TabWrapper = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  borderBottom: '1px solid #e6e6e6',
  padding: theme.spacing(0, 3),
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  '& .MuiTabs-root': {
    border: '0px !important',
  },
}));
