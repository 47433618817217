import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { UserAvatar } from '~/components/UserAvatar';
import { useAppContext } from '~/contexts';
import { assert } from '~/lib/assert';
import { NavItemLink } from './NavItems';
import { UserActionsPopover } from './UserActions';

const UserButtonLink = styled(NavItemLink)(({ theme }) => ({
  '& span svg': {
    color: theme.palette.common.white,
  },
  '& .MuiListItemText-root  .MuiTypography-root': {
    color: theme.palette.common.white,
  },
}));

export const UserButton = () => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { currentUser } = useAppContext();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = ({
    target,
  }: React.MouseEvent<HTMLElement | Document> | MouseEvent | TouchEvent) => {
    assert(target instanceof Node, 'Unexpected error');
    if (!buttonRef.current?.contains(target)) setOpen(false);
  };

  return (
    <Box>
      <UserButtonLink
        ref={buttonRef}
        endIcon={open ? <ExpandLess /> : <ExpandMore />}
        icon={<UserAvatar user={currentUser} />}
        onClick={handleToggle}
        text={currentUser.name}
      />
      <UserActionsPopover anchorEl={buttonRef} isOpen={open} onCloseMenu={handleClose} />
    </Box>
  );
};
