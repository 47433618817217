import { Divider, Menu as MuiMenu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDeleteChannelGuide } from '~/api/channel-guides/delete';
import { ConfirmDialog } from '~/components/dialogs/confirmation';
import { MoreDeleteAction } from '~/components/table';
import { useAppContext } from '~/contexts';
import { useConfirmDialog } from '~/hooks/dialogs';
import { type MoreActionProps, type MoreMenuProps } from '~/hooks/table';
import { assert } from '~/lib/assert';
import type { ChannelGuideSettings__ChannelGuide as ChannelGuide } from '../../queries/settings.generated';

export interface MenuProps {
  channelGuide: ChannelGuide | null | undefined;
  moreMenuProps: MoreMenuProps<ChannelGuide>;
  moreActionProps: MoreActionProps;
}

export const Menu = ({ moreMenuProps, moreActionProps }: MenuProps) => {
  const { currentNetwork } = useAppContext();
  const navigate = useNavigate();
  const [deleteChannelGuide] = useDeleteChannelGuide();
  const [confirmDelete, confirmDeleteProps] = useConfirmDialog();

  return (
    <>
      <MuiMenu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        {...moreMenuProps}
      >
        <span>
          <Divider />
          <MoreDeleteAction
            {...moreActionProps}
            onClick={async () => {
              assert(moreMenuProps.context != null, 'delete: no device context');
              if (!(await confirmDelete())) return;
              await deleteChannelGuide({
                variables: {
                  input: {
                    channelGuideIds: [moreMenuProps.context.id],
                    networkId: currentNetwork.id,
                  },
                },
              });
              navigate('../..');
              moreMenuProps.onClose();
            }}
          />
        </span>
      </MuiMenu>

      <ConfirmDialog
        {...confirmDeleteProps}
        confirm="Permanently Delete"
        deleteConfirm
        prompt="Deleting this Channel Guide will remove it from Devices where it is being used."
        title="Delete Channel Guide"
      />
    </>
  );
};
