import { ExpandLess, ExpandMore } from '@mui/icons-material';
import type { InputBaseProps } from '@mui/material';
import {
  Box,
  Collapse,
  InputBase,
  Menu,
  MenuItem,
  MenuList,
  styled,
  useTheme,
} from '@mui/material';
import { forwardRef, useMemo, useRef, useState } from 'react';
import { ButtonLink } from '~/components/link';
import { ListItemText } from '~/components/list-row';
import { useAppContext } from '~/contexts';
import { useLink } from '~/hooks/link';
import { NavItemLink } from './NavItems';
import { NetworkAvatar } from './NetworkAvatar';

export const FilterMenuItem = styled(MenuItem)({
  alignContent: 'center',
  alignItems: 'center',
  borderBottom: '1px solid rgb(63, 63, 63, .15)',
  display: 'flex',
  gap: 10,
  justifyContent: 'space-between',
  width: '100%',

  '&, &.MuiButtonBase-root, &:hover, &.MuiListItem-root.Mui-focusVisible': {
    backgroundColor: 'white',
  },

  '& .MuiButton-root': {
    textTransform: 'none',
    padding: '2px 8px',
    transition: 'none !important',
  },
});

export const FilterTextField = styled(
  forwardRef<HTMLInputElement, InputBaseProps>(function FilterTextField(props, ref) {
    return <InputBase type="text" {...props} ref={ref} />;
  }),
)({ flexGrow: 1 });

export const NetworkMenuItem = styled(MenuItem)({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid #e8e8e8',

  '&:last-child': {
    borderBottom: '0',
  },

  '& .MuiIconButton-root': {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const NetworkMenuItemLink = styled(ButtonLink)({
  alignContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'transparent',
  border: 0,
  borderRadius: 0,
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  justifyContent: 'flex-start',
  margin: 0,
  padding: 0,
  textTransform: 'none',
  width: '100%',

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiListItemText-root': {
    textAlign: 'left',
  },
});

const NetworkButtonLink = styled(NavItemLink)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  backgroundColor: '#4096d0',
  '& span svg': {
    color: theme.palette.common.white,
  },
  '& .MuiListItemText-root  .MuiTypography-root': {
    color: theme.palette.common.white,
  },
}));

export const NetworkButton = () => {
  const { currentNetwork, currentUser } = useAppContext();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const link = useLink();

  const theme = useTheme();

  const networks = useMemo(
    () =>
      currentUser.networks.filter((network) => {
        return currentUser.admin ? network : !network.public;
      }),
    [currentUser],
  );

  const handleToggle = () => {
    if (networks.length <= 1) return;
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <Box>
      <NetworkButtonLink
        ref={buttonRef}
        endIcon={networks.length > 1 ? open ? <ExpandLess /> : <ExpandMore /> : null}
        icon={<NetworkAvatar icon={currentNetwork.logoUrl} />}
        onClick={handleToggle}
        disabled={networks.length === 1}
        text={currentNetwork.name}
      />
      <Menu
        TransitionComponent={Collapse}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        marginThreshold={0}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        TransitionProps={{
          onEntered: () => inputRef.current?.focus(),
          onExited: () => setFilter(''),
        }}
        anchorEl={buttonRef.current}
        onClose={handleToggle}
        open={open}
        sx={{
          '& .MuiPaper-root': {
            overflow: 'hidden',
            width: '300px',
            border: '1px solid #e8e8e8',
            marginTop: '4px',
          },
        }}
      >
        <FilterMenuItem>
          <FilterTextField
            inputRef={inputRef}
            onChange={(event) => setFilter(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
            placeholder="Filter networks"
          />
        </FilterMenuItem>

        <MenuList
          sx={{
            maxHeight: 500,
            overflowY: 'auto',
          }}
        >
          {networks.map((network) => {
            if (!network.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())) return null;
            return (
              <NetworkMenuItem
                disableRipple
                key={network.id}
                disabled={network.id === currentNetwork.id}
              >
                <NetworkMenuItemLink
                  onClick={() => setOpen(false)}
                  to={link('/dashboard', network.id)}
                >
                  <NetworkAvatar icon={network.logoUrl} sx={{ marginRight: theme.spacing(1) }} />
                  <ListItemText>{network.name}</ListItemText>
                </NetworkMenuItemLink>
              </NetworkMenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};
