import { Box } from '@mui/system';
import { ListRowHeadingValue } from '~/components/list-row';
import { MetaItem, SettingsBody, SettingsData, SettingsHead } from '~/components/settings';
import { formatDate } from '~/lib/datetime';
import type { ChannelGuideSettings__ChannelGuide as ChannelGuide } from '../../queries/settings.generated';

export interface DetailsProps {
  channelGuide: ChannelGuide;
}

export const Details = ({ channelGuide }: DetailsProps) => {
  return (
    <Box>
      <SettingsHead>Details</SettingsHead>
      <SettingsBody>
        <SettingsData className="last no-space-between">
          <Box sx={{ display: 'flex' }}>
            <MetaItem>
              <ListRowHeadingValue heading="Channels" value={channelGuide.channels.length} />
            </MetaItem>
            <MetaItem>
              <ListRowHeadingValue heading="Shows" value={channelGuide.showCount} />
            </MetaItem>
            <MetaItem>
              <ListRowHeadingValue
                heading="Default Channel"
                value={channelGuide.defaultChannel?.name ?? 'N/A'}
              />
            </MetaItem>
            <MetaItem>
              <ListRowHeadingValue
                heading="Created At"
                value={formatDate(channelGuide.createdAt)}
              />
            </MetaItem>
            <MetaItem className="last">
              <ListRowHeadingValue
                heading="Last Updated"
                value={formatDate(channelGuide.updatedAt)}
              />
            </MetaItem>
          </Box>
        </SettingsData>
      </SettingsBody>
    </Box>
  );
};
