import { DeviceHub } from '@mui/icons-material';
import { Box, Button, Chip, MenuItem, Select, Tooltip, type DialogProps } from '@mui/material';
import type { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useAddToRole } from '~/api/roles';
import { SeparatedDataGrid } from '~/components/data-grid';
import { DeviceIndicators } from '~/components/devices';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import { RuleAccess } from '~/generated/graphql';
import { searchRegex } from '~/lib/search';
import { RuleAccessLabels } from '../../lib';
import type {
  RoleDeviceList__Device,
  RoleDeviceList__DeviceGroup,
  RoleDeviceList__DeviceGroupRule,
  RoleDeviceList__DeviceRule,
  RoleDeviceList__Role,
} from '../RoleDeviceList.generated';
import { MultiCount } from './MulitCount';

const itemId = (item: RoleDeviceList__DeviceGroup | RoleDeviceList__Device): string =>
  `${item.__typename}:${item.id}`;

const ruleItemId = (rule: RoleDeviceList__DeviceGroupRule | RoleDeviceList__DeviceRule): string =>
  rule.__typename === 'DeviceRule' ? itemId(rule.device) : itemId(rule.deviceGroup);

export interface AddDevicesDialogProps extends Omit<DialogProps, 'role'> {
  deviceGroups: readonly RoleDeviceList__DeviceGroup[];
  devices: readonly RoleDeviceList__Device[];
  role: RoleDeviceList__Role;
}

export const AddDevicesDialog = ({
  deviceGroups,
  devices,
  role,
  ...props
}: AddDevicesDialogProps) => {
  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [accessValues, setAccessValues] = useState<Record<string, RuleAccess | undefined>>({});

  const handleAccessChange = (id: string, access: RuleAccess) => {
    setAccessValues((prev) => ({ ...prev, [id]: access }));
  };

  const deviceGroupRules = selectedIds
    .filter((id) => String(id).startsWith('DeviceGroupNew:'))
    .map((id) => ({
      deviceGroupId: Number(String(id).split(':')[1]),
      access: accessValues[id] || RuleAccess.Write,
    }));

  const deviceRules = selectedIds
    .filter((id) => String(id).startsWith('Device:'))
    .map((id) => ({
      deviceId: Number(String(id).split(':')[1]),
      access: accessValues[id] || RuleAccess.Write,
    }));

  const [addToRole, { called }] = useAddToRole({
    variables: { roleId: role.id, deviceGroupRules, deviceRules },
  });

  const itemIds = new Set<GridRowId>(
    [...role.deviceGroupRules, ...role.deviceRules].map(ruleItemId),
  );

  const searchReg = searchRegex(search);

  const rows = [...deviceGroups, ...devices].filter(
    (item) =>
      !itemIds.has(itemId(item)) && (searchReg.test(String(item.id)) || searchReg.test(item.name)),
  );

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Devices</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Devices to add to the <b>{role.name}</b> role.
        </DialogContentTitle>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Devices" search={search} />
          <MultiCount
            descriptors={[
              {
                matchesItem: (item) => item.__typename === 'DeviceGroupNew',
                matchesSelectedId: (id) => String(id).startsWith('DeviceGroupNew:'),
                name: 'device group',
              },
              {
                matchesItem: (item) => item.__typename === 'Device',
                matchesSelectedId: (id) => String(id).startsWith('Device:'),
                name: 'device',
              },
            ]}
            items={rows}
            selectedIds={selectedIds}
          />
        </Box>

        <Box>
          <SeparatedDataGrid
            autoHeight={false}
            checkboxSelection
            columns={[
              {
                align: 'center',
                headerAlign: 'center',
                field: 'type',
                headerName: '',
                width: 150,
                renderCell: ({ row }) =>
                  row.__typename === 'Device' ? (
                    <DeviceIndicators device={row} />
                  ) : (
                    <Tooltip title="Device Group" arrow>
                      <DeviceHub color="primary" />
                    </Tooltip>
                  ),
                sortable: false,
              },
              { field: 'id' },
              { field: 'name', flex: 1 },
              {
                field: 'access',
                renderCell: ({ row }) => {
                  const id = itemId(row);
                  return (
                    <Select
                      disabled={!selectedIds.includes(id)}
                      fullWidth
                      name="access"
                      size="small"
                      value={accessValues[id] || RuleAccess.Write}
                      onChange={(e) => handleAccessChange(id, e.target.value as RuleAccess)}
                    >
                      <MenuItem value={RuleAccess.Read}>
                        {RuleAccessLabels[RuleAccess.Read]}
                      </MenuItem>
                      <MenuItem value={RuleAccess.Write}>
                        {RuleAccessLabels[RuleAccess.Write]}
                      </MenuItem>
                    </Select>
                  );
                },
                width: 150,
              },
              {
                field: 'devices',
                align: 'center',
                headerAlign: 'center',
                renderCell: ({ row }) =>
                  row.__typename === 'Device' ? (
                    '--'
                  ) : (
                    <Chip color="primary" label={row.deviceCount} />
                  ),
              },
            ]}
            getRowId={(row) => `${row.__typename}:${row.id}`}
            onRowSelectionModelChange={setSelectedIds}
            rowSelectionModel={selectedIds}
            rows={rows}
            slots={{
              baseCheckbox: ListCheckbox,
              columnResizeIcon: () => null,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No devices exist
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || !selectedIds.length}
          onClick={() => {
            close();
            return addToRole();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
