import { Box, Button, type DialogProps } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useAddToRole } from '~/api/roles';
import { Count } from '~/components/count';
import { SeparatedDataGrid } from '~/components/data-grid';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import { searchRegex } from '~/lib/search';
import type {
  RolePlaylistList__Playlist,
  RolePlaylistList__Role,
} from '../RolePlaylistList.generated';

export interface AddPlaylistsDialogProps extends Omit<DialogProps, 'role'> {
  role: RolePlaylistList__Role;
  playlists: readonly RolePlaylistList__Playlist[];
}

export const AddPlaylistsDialog = ({ role, playlists, ...props }: AddPlaylistsDialogProps) => {
  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [addPlaylists, { called }] = useAddToRole({
    variables: {
      roleId: role.id,
      playlistRules: selectedIds.map((id) => ({ playlistId: id as number })),
    },
  });

  const rolePlaylistIds = new Set(
    role.playlistRules.map((playlistRule) => playlistRule.playlist.id),
  );

  const searchReg = searchRegex(search);

  const rows = playlists.filter(
    (playlist) => !rolePlaylistIds.has(playlist.id) && searchReg.test(playlist.name),
  );

  // Sucks
  const close = () => props.onClose?.({}, 'backdropClick');

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Playlists</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Playlists to add to the <b>{role.name}</b> role.
        </DialogContentTitle>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Playlists" search={search} />
          <Count selectedCount={selectedIds.length} totalCount={rows.length} thing="playlist" />
        </Box>

        <Box>
          <SeparatedDataGrid
            autoHeight={false}
            checkboxSelection
            columns={[{ field: 'name', flex: 1 }]}
            onRowSelectionModelChange={setSelectedIds}
            rows={rows}
            rowSelectionModel={selectedIds}
            slots={{
              baseCheckbox: ListCheckbox,
              columnResizeIcon: () => null,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No playlists exist
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || !selectedIds.length}
          onClick={() => {
            close();
            return addPlaylists();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
