import { Box, Button, type DialogProps } from '@mui/material';
import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useAddToRole } from '~/api/roles';
import { Count } from '~/components/count';
import { SeparatedDataGrid } from '~/components/data-grid';
import { Dialog, DialogContentTitle, DialogTitle } from '~/components/dialogs/components';
import { DialogActions, DialogContent } from '~/components/dialogs/lib';
import { SearchBar } from '~/components/forms/search-bar';
import { ListCheckbox } from '~/components/list-row';
import { searchRegex } from '~/lib/search';
import {
  type RoleDataSourceList__DataSource as DataSource,
  type RoleDataSourceList__Role as Role,
} from '../RoleDataSourceList.generated';

export interface AddDataSourcesDialogProps extends Omit<DialogProps, 'role'> {
  dataSources: readonly DataSource[];
  role: Role;
}

export const AddDataSourcesDialog = ({
  dataSources,
  role,
  ...props
}: AddDataSourcesDialogProps) => {
  const [search, setSearch] = useState('');

  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);

  const [add, { called }] = useAddToRole({
    variables: {
      roleId: role.id,
      dataSourceRules: selectedIds.map((id) => ({ dataSourceId: id as number })),
    },
  });

  const roleIds = new Set(role.dataSourceRules.map((x) => x.dataSource.id));

  const searchReg = searchRegex(search);

  const rows = dataSources.filter((x) => !roleIds.has(x.id) && searchReg.test(x.name));

  const close = () => props.onClose?.({}, 'backdropClick');

  return (
    <Dialog {...props}>
      <DialogTitle onClose={close}>Add Data Sources</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentTitle>
          Select Data Sources to add to the <b>{role.name}</b> role.
        </DialogContentTitle>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <SearchBar onChange={setSearch} placeholder="Search Data Sources" search={search} />
          <Count selectedCount={selectedIds.length} totalCount={rows.length} thing="data source" />
        </Box>

        <Box>
          <SeparatedDataGrid
            checkboxSelection
            columns={[{ field: 'name', flex: 1 }]}
            onRowSelectionModelChange={setSelectedIds}
            rows={rows}
            rowSelectionModel={selectedIds}
            slots={{
              baseCheckbox: ListCheckbox,
              columnResizeIcon: () => null,
              noRowsOverlay: () => {
                return (
                  <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                    No data sources exist
                  </Box>
                );
              },
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={called} onClick={close} variant="outlined">
          Cancel
        </Button>

        <Button
          color="primary"
          disabled={called || !selectedIds.length}
          onClick={() => {
            close();
            return add();
          }}
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
