import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { UpdateChannelGuideDocument } from './update.generated';

export const useUpdateChannelGuide = (
  options: ApiOptions<typeof UpdateChannelGuideDocument> = {},
) =>
  useMutation(UpdateChannelGuideDocument, {
    ...useNotifications(UpdateChannelGuideDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Updated Channel Guide',
    }),

    ...options,
  });
