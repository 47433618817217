import { Box, Chip } from '@mui/material';
import { useDeviceFilters } from '../context';

export const FilterChips = () => {
  const { filters, setFilter } = useDeviceFilters();

  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {filters.arch.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`arch-${f.value}`}
            label={`Arch: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.arch.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'arch', items });
            }}
          />
        ))}
      {filters.demo.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`demo-${f.value}`}
            label={`Demo: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.demo.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'demo', items });
            }}
          />
        ))}
      {filters.displayOn.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`display-${f.value}`}
            label={`Display: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.displayOn.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'displayOn', items });
            }}
          />
        ))}
      {filters.groups.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`group-${f.value}`}
            label={`Group: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.groups.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'groups', items });
            }}
          />
        ))}
      {filters.internal.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`internal-${f.value}`}
            label={`Internal: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.internal.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'internal', items });
            }}
          />
        ))}
      {filters.kind.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`kind-${f.value}`}
            label={`Type: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.kind.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'kind', items });
            }}
          />
        ))}
      {filters.silence.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`silence-${f.value}`}
            label={`Silenced: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.silence.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'silence', items });
            }}
          />
        ))}
      {filters.status.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`status-${f.value}`}
            label={`Status: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.status.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'status', items });
            }}
          />
        ))}
      {filters.tags.items
        .filter((f) => f.selected)
        .map((f) => (
          <Chip
            key={`tag-${f.value}`}
            label={`Tag: ${f.label}`}
            color="primary"
            onDelete={() => {
              const items = filters.tags.items.map((x) =>
                x.value === f.value ? { ...x, selected: false } : x,
              );
              setFilter({ field: 'tags', items });
            }}
          />
        ))}
    </Box>
  );
};
