import { useMutation } from '@apollo/client';
import { type ApiOptions, useNotifications } from '../helpers';
import { DeleteChannelDocument } from './delete.generated';

export const useDeleteChannel = (options: ApiOptions<typeof DeleteChannelDocument> = {}) =>
  useMutation(DeleteChannelDocument, {
    ...useNotifications(DeleteChannelDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: (data) => {
        const count = data.deleteChannel?.deletedIds.length;
        return `Deleted ${count != null && count > 1 ? count : ''} Channel${
          count !== 1 ? 's' : ''
        }`;
      },
    }),

    ...options,
  });
