import { useMutation } from '@apollo/client';
import { useNotifications, type ApiOptions } from '../helpers';
import { CreateChannelGuideDocument } from './create.generated';

export const useCreateChannelGuide = (
  options: ApiOptions<typeof CreateChannelGuideDocument> = {},
) =>
  useMutation(CreateChannelGuideDocument, {
    ...useNotifications(CreateChannelGuideDocument, {
      failure: 'An error occurred. Reload and try again or contact support.',
      success: 'Created Channel Guide',
    }),

    ...options,
  });
