import type { ListItemIconProps, ListItemProps, ListItemTextProps } from '@mui/material';
import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';
import type { ComponentProps, ElementType, ReactNode, Ref } from 'react';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useSideNav } from '../context';

export interface NavItemIconProps extends ListItemIconProps {
  icon: ReactNode;
  tooltip: string;
}

export const NavItemIcon = ({ icon, tooltip, ...props }: NavItemIconProps) => {
  const theme = useTheme();
  const { open } = useSideNav();
  return (
    <ListItemIcon
      sx={{
        ...(open && {
          marginRight: theme.spacing(1),
        }),
        ...(!open && {
          marginRight: 0,
          justifyContent: 'center',
        }),
        '&.MuiListItemIcon-root': {
          color: '#BFE5FF',
        },
      }}
      {...props}
    >
      <Tooltip arrow title={open ? '' : tooltip} placement="right">
        <Box sx={{ display: 'flex' }}>{icon}</Box>
      </Tooltip>
    </ListItemIcon>
  );
};

export interface NavItemTextProps extends ListItemTextProps {
  text: string;
}

export const NavItemText = ({ text, ...props }: NavItemTextProps) => {
  const { open } = useSideNav();
  return (
    <ListItemText
      {...props}
      primary={open ? text : ''}
      sx={{
        ...(!open && {
          display: 'none',
        }),
        textAlign: 'left',
        overflow: 'hidden',
        '& .MuiTypography-root': {
          color: '#BFE5FF',
          fontSize: '1rem',
          fontWeight: 600,
        },
      }}
    />
  );
};

export type NavItemLinkProps<T extends ElementType = 'div'> = ListItemProps<T> &
  ComponentProps<typeof ListItem> & {
    endIcon?: ReactNode;
    icon: ReactNode;
    sub?: boolean;
    text: string;
    href?: string; // href for traditional anchor tags
    to?: string; // to for React Router's Link
  };

export const NavItemLink = forwardRef(function NavItemLink<T extends ElementType>(
  { endIcon, icon, sub, sx, text, to, href, ...props }: NavItemLinkProps<T>,
  ref: Ref<HTMLElement>,
) {
  const theme = useTheme();
  const { open } = useSideNav();

  const isReactRouterLink = Boolean(to);
  const Component = isReactRouterLink ? Link : 'a';

  return (
    <ListItem
      component={Component}
      {...(isReactRouterLink ? { to } : { href })}
      sx={{
        ...(!open && {
          justifyContent: 'center',
        }),
        color: '#BFE5FF',
        margin: theme.spacing(0.5, 1),
        padding: sub ? (open ? theme.spacing(1, 4) : theme.spacing(1, 2)) : theme.spacing(1, 2),
        whiteSpace: 'nowrap',
        width: 'inherit',
        cursor: 'pointer',
        '&.Mui-disabled': {
          cursor: 'default',
          opacity: 1,
          '& .MuiTypography-root': {
            color: 'common.white',
          },
        },
        '&.Mui-selected': {
          borderRadius: theme.spacing(0.5),
          backgroundColor: '#003F72',
          '& .MuiListItemIcon-root, & .MuiTypography-root': {
            color: theme.palette.common.white,
          },
        },
        '&:hover, &.Mui-selected:hover': {
          borderRadius: theme.spacing(0.5),
          backgroundColor: '#4096d0',
          '& .MuiListItemIcon-root, & .MuiTypography-root': {
            color: theme.palette.common.white,
          },
        },
        ...sx,
      }}
      disableGutters
      {...props}
      ref={ref}
    >
      <NavItemIcon tooltip={text} icon={icon} />
      <NavItemText text={text} />
      {open && <span style={{ display: 'flex' }}>{endIcon}</span>}
    </ListItem>
  );
});

export const NavDivider = styled(Divider)(() => ({
  opacity: 0.25,
}));
