import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

interface SideNavContextType {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggleDrawer: () => void;
}

const SideNavContext = createContext<SideNavContextType | undefined>(undefined);

export const SideNavProvider = ({
  children,
  initialOpen = false,
}: {
  children: ReactNode;
  initialOpen?: boolean;
}) => {
  const [open, setOpen] = useState(initialOpen);

  const toggleDrawer = () => setOpen((prev) => !prev);

  return (
    <SideNavContext.Provider value={{ open, setOpen, toggleDrawer }}>
      {children}
    </SideNavContext.Provider>
  );
};

export const useSideNav = () => {
  const context = useContext(SideNavContext);
  if (!context) {
    throw new Error('useSideNav must be used within a SideNavProvider');
  }
  return context;
};
